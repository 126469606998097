var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('navigation',[_c('div',{staticStyle:{"display":"flex","gap":"5px"}},[_c('div',[_c('router-link',{attrs:{"to":{ name: 'add_contract', params: { company_id: _vm.data.id } }}},[_c('rg-button',{staticStyle:{"border-top-right-radius":"0","border-bottom-right-radius":"0"},attrs:{"type":"is-primary","icon-left":"file-contract","title":"Anlegen","label":"Anlegen"}})],1),_c('rg-dropdown',{scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('rg-button',{staticStyle:{"border-top-left-radius":"0","border-bottom-left-radius":"0"},attrs:{"icon":"chevron-down"}})]},proxy:true}])},[_c('router-link',{attrs:{"to":{ name: 'add_contract', params: { company_id: _vm.data.id } }}},[_c('rg-button',{attrs:{"icon-left":"file-contract","label":"Vertrag"}})],1),_c('router-link',{attrs:{"to":"/companies/add"}},[_c('rg-button',{attrs:{"icon-left":"users","label":"Unternehmen"}}),_c('router-link',{attrs:{"to":"/associations/add"}},[_c('rg-button',{attrs:{"icon-left":"building","label":"Unternehmensgruppe"}})],1),_c('router-link',{attrs:{"to":{
                name: 'add_account',
                params: {
                  granitid: _vm.data.granitId,
                  company: _vm.data.title,
                  country: _vm.data.Country ? _vm.data.Country.country_code : null,
                },
              }}},[_c('rg-button',{attrs:{"icon-left":"user-plus","label":"Account"}})],1)],1)],1)],1),(_vm.data.granitid)?_c('a',{attrs:{"target":"_blank","href":("https://metabase.repguide.de/question/389-visited-machines-of-a-specific-company?granit_id=" + (_vm.data.granitid))}},[_c('rg-button',{attrs:{"icon-left":"tractor","label":"Maschinenhistorie"}})],1):_vm._e()])]),_c('main',[_c('h1',[_c('fa',{staticClass:"fa-fw",attrs:{"icon":"industry"}}),_vm._v(_vm._s(_vm.data.title))],1),_c('div',[_c('code',{staticStyle:{"font-weight":"bold"},attrs:{"title":"ID"}},[_vm._v(" "+_vm._s(_vm.data.id)+" ")]),_c('span',{staticStyle:{"opacity":"0.5"}},[_vm._v(" | ")]),_c('code',{staticStyle:{"font-weight":"bold"},attrs:{"title":"Granit-ID"}},[_vm._v(" "+_vm._s(_vm.data.granitId)+" ")])]),_c('br'),_c('rg-tabs',[_c('router-link',{attrs:{"tag":"button","replace":"","to":'/companies/' + _vm.$route.params.id + '/home'}},[_c('fa',{attrs:{"icon":"industry"}}),_vm._v(" Übersicht ")],1),_c('router-link',{attrs:{"tag":"button","replace":"","to":'/companies/' + _vm.$route.params.id + '/accounts'}},[_c('fa',{attrs:{"icon":"users"}}),_vm._v(" Benutzer ")],1),_c('router-link',{attrs:{"tag":"button","replace":"","to":'/companies/' + _vm.$route.params.id + '/contracts'}},[_c('fa',{attrs:{"icon":"file-contract"}}),_vm._v(" Verträge ")],1)],1),_c('br'),_c('br'),_c('router-view',{attrs:{"data":_vm.data}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }