<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/accounts/add">
            <rg-button
              type="is-primary"
              icon-left="user-plus"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template #trigger>
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/accounts/add">
              <rg-button icon-left="user-plus" label="Account" />
            </router-link>
            <router-link to="/companies/add">
              <rg-button icon-left="users" label="Unternehmen" />
            </router-link>
            <router-link to="/associations/add">
              <rg-button icon-left="building" label="Unternehmensgruppe" />
            </router-link>
            <router-link to="/contracts/add">
              <rg-button icon-left="file-contract" label="Vertrag" />
            </router-link>
          </rg-dropdown>
        </div>
      </div>
    </navigation>
    <main>
      <h1>Nutzerverwaltung</h1>
      <br />
      <rg-tabs>
        <router-link tag="button" replace to="/accounts/">
          <fa icon="users" />&nbsp;Accounts
        </router-link>
        <router-link tag="button" replace to="/companies/">
          <fa icon="industry" />&nbsp;Unternehmen
        </router-link>
        <router-link tag="button" replace to="/associations/">
          <fa icon="building" />&nbsp;Unternehmensgruppen
        </router-link>
      </rg-tabs>
      <br />
      <input
        v-model="resultFilter.search"
        type="text"
        placeholder="Unternehmensname, Granit-ID"
        @input="dispatchSearch()"
        @keypress.enter="dispatchSearch(0)"
      />
      <br />
      <div v-if="!resultFilter.loading">
        <h2>{{ userCount }} Ergebnisse</h2>
        <rg-button
          :disabled="page.current <= 1"
          icon="angle-double-left"
          @click="stride(page.current, 1)"
        />
        <rg-button
          :disabled="page.current <= 1"
          icon="angle-left"
          @click="stride(page.current, page.current - 1)"
        />
        {{ page.current }} von {{ Math.ceil(pages) }}
        <rg-button
          :disabled="page.current >= Math.ceil(pages)"
          icon="angle-right"
          @click="stride(page.current, page.current + 1)"
        />
        <rg-button
          :disabled="page.current >= Math.ceil(pages)"
          icon="angle-double-right"
          @click="stride(page.current, Math.ceil(pages))"
        />
      </div>
      <table class="rg-table condensed">
        <div v-show="resultFilter.loading">LOADING</div>
        <thead>
          <th>ID</th>
          <th>Login</th>
          <th>E-Mail</th>
          <th>Vorname</th>
          <th>Nachname</th>
          <th>Status</th>
          <th>Rollen</th>
          <th>Unternehmens-ID</th>
          <th>Unternehmen</th>
          <th>Land</th>
          <th>Sprache</th>
          <th style="text-align:center">Aktionen</th>
        </thead>
        <tr 
          v-for="row in data" 
          :key="row.id"
        >
          <td>
            {{ row.id }}
          </td>
          <td>
            {{ row.login }}
          </td>
          <td>
            {{ row.email }}
          </td>
          <td>
            {{ row.prename }}
          </td>
          <td>
            {{ row.name }}
          </td>
          <td>
            {{ row.status }}
          </td>
          <td>
            {{ row.roles.join(", ") }}
          </td>
          <td>
            {{ row.Company.id }}
          </td>
          <td>
            {{ row.Company.company_title }}
          </td>
          <td>
            {{ row.country }}
          </td>
          <td>
            {{ row.language_for_contact.language_code }}
          </td>
          <td>
            <div class="flex-right" style="gap: 5px">
              <router-link :to="'/accounts/' + row.id">
                <rg-button
                  icon-left="user"
                  label="Öffnen"
                  type="is-primary"
                  title="Diesen Eintrag bearbeiten"
                />
              </router-link>
              <router-link :to="`/companies/${row.Company.id}/home`">
                <rg-button
                  icon-left="building"
                  label="Öffnen"
                  title="Unternehmen dieses Accounts anzeigen"
                />
              </router-link>
              <rg-button
                icon-left="headset"
                label="Ticket"
                @click="createTicket(row)"
              />
            </div>
          </td>
        </tr>
      </table>
    </main>
  </div>
</template>

<script>
import rg_tabs from "@/components/elements/rg-tabs";
import { ref } from "@vue/composition-api";
import dropdown from "@/components/elements/rg-dropdown";
import superagent from "superagent";
import Vue from "vue";
// import { altBackendUrl } from "../../constants";
export default {
  name: "Accounts",
  components: {
    "rg-dropdown": dropdown,
    rgTabs: rg_tabs,
  },

  // eslint-disable-next-line no-unused-vars
  setup(props, context) {
    let data = ref([]);
    let resultFilter = ref({
      step: 25,
      first: 25,
      offset: null,
      search: "",
      loading: true,
      timeout: null,
    });
    let userCount = ref({});
    let pages = ref({});
    let page = ref({
      current: 1,
    });

    getData();

    const dispatchSearch = (ms = 300) => {
      clearTimeout(resultFilter.value.timeout);

      resultFilter.value.timeout = setTimeout(() => {
        resultFilter.value.loading = true;
        resultFilter.value.offset = null;
        page.value.current = 1;
        getData(resultFilter.value.search);
      }, resultFilter.value.search?.length && ms);
    };

    dispatchSearch(0);

    function getData(search = resultFilter.value.search) {
      search = search?.length >= 3 ? search : "*";
      superagent
        .get(
          `https://repguide-rest.fricke-holding.workers.dev/api/legacy/users/by-search-plus/${search}/limit/${resultFilter.value.first}/offset/${resultFilter.value.offset}`
        )
        .then((res) => {
          data.value = res.body.data.Users;
          userCount.value = res.body.data.Statistics.totalUsersCount;
          pages.value = userCount.value / resultFilter.value.step;
        })
        .finally(() => {
          resultFilter.value.loading = false;
        });
    }

    function clipboardSuccessHandler() {
      Vue.$toast.open({
        message: "Erfolgreich kopiert",
        icon: "clipboard-check",
      });
    }

    function stride(from, to) {
      page.value.current = to;
      resultFilter.value.offset =
        page.value.current === 1
          ? null
          : resultFilter.value.step * page.value.current -
            resultFilter.value.step;
      getData();
    }

    function createTicket(ctx, subject = "") {
      window.open(
        `https://frixlab.fricke.de/innovationlab/repguide/support/-/issues/new?issue[title]=${ctx.id}%20-%20${ctx.login}%20-%20${ctx.Company.granitid}%20-%20${subject}`,
        "_blank"
      );
    }

    return {
      data,
      clipboardSuccessHandler,
      dispatchSearch,
      resultFilter,
      page,
      pages,
      userCount,
      stride,
      createTicket,
    };
  },
};
</script>
