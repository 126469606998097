<template>
  <div class="rg-grid">
    <div class="level w12">
      <h2>Stammdaten bearbeiten</h2>
      <div class="level">
        <span>Bearbeitung</span>
        <label class="switch" style="margin: 0 5px 0 10px">
          <input
            v-model="editable.form"
            type="checkbox"
            @change="toggleEdit($event)"
          />
          <span class="slider" />
        </label>
        <rg-button
          type="is-primary"
          :disabled="!editable.form || !checkFormInput()"
          icon-left="save"
          label="Speichern"
          @click="saveChanges()"
        />
      </div>
    </div>
    <rg-widget title="Allgemein" class="w6 form">
      <template #data>
        <strong class="level"
          ><span>Firma</span>
          <span
            v-if="editable.form && formDirty(data.title, editable.data.title)"
            :class="$style.changed"
          />
        </strong>
        <input v-if="editable.form" v-model="editable.data.title" type="text" />
        <input v-else :value="data.title" disabled type="text" />

        <div style="display: flex">
          <div style="margin-right: 5px">
            <strong>GRANIT Kundennr.</strong>
            <span
              v-if="existingCompany && data.granitId !== editable.data.granitId"
              style="color: red"
              >Granit Kundennr wird bereits von einer anderen Firma
              verwendet</span
            >
            <input
              v-if="editable.form"
              v-model="editable.data.granitId"
              type="text"
              @blur="dispatchSearch()"
              @keypress.enter="dispatchSearch()"
              @input="dispatchSearch()"
            />
            <input v-else :value="data.granitId" disabled type="text" />
          </div>
          <div style="margin-right: 5px">
            <strong v-if="!granitIdIsSAPId">SAP ID</strong>
            <span
              v-if="existingSapID && !granitIdIsSAPId"
              style="color: red; margin-left: 5px"
            >
              <fa
                class="fa-far"
                icon="exclamation-triangle"
                style="cursor: help"
                @mouseover="showTooltip = true"
                @mouseleave="showTooltip = false"
              />
              <div
                v-if="showTooltip"
                style="
                  background-color: var(--input-background);
                  color: red;
                  text-align: center;
                  padding: 5px;
                  border-radius: 7px;
                  position: absolute;
                  z-index: 1;
                  transform: translateY(-170%) translateX(15%);
                  border: 2px solid red;
                "
              >
                Die SAP ID wird bereits von einer anderen Firma verwendet
              </div>
            </span>
            <input
              v-if="editable.form && !granitIdIsSAPId"
              v-model="editable.data.sapId"
              type="text"
              @blur="lookupSapId()"
              @keypress.enter="lookupSapId()"
              @input="lookupSapId()"
            />
            <input
              v-else-if="!granitIdIsSAPId"
              :value="data.sapId"
              disabled
              type="text"
            />
          </div>

          <div>
            <div>
              <strong> Kundennr. ist SAP Kundennr. </strong>
            </div>
            <label class="switch" style="margin-top: 6px">
              <input
                v-if="!editable.form"
                v-model="granitIdIsSAPId"
                type="checkbox"
                disabled="true"
              />
              <input v-else v-model="granitIdIsSAPId" type="checkbox" />
              <span class="slider" />
            </label>
          </div>
        </div>
        <strong class="level"
          ><span>GRANIT Shop</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.granitShopId, editable.data.granitShopId)
            "
            :class="$style.changed"
          />
        </strong>
        <select
          v-if="!editable.form"
          :value="data.granitShopId"
          disabled
          class="rg-select"
        >
          <option
            v-for="option in granitShops.filter((shop) =>
              data.Country
                ? shop.country_ids.includes(parseInt(data.Country.id)) ||
                  shop.id == data.granitShopId
                : null
            )"
            :key="option.id"
            :value="option.id"
            :selected="option.id === data.granitShopId"
          >
            {{ option.title }} {{ `(${option.base_domain})` }}
          </option>
        </select>
        <select v-else v-model="editable.data.granitShopId" class="rg-select">
          >
          <option
            v-for="option in granitShops.filter((shop) =>
              data.Country
                ? shop.country_ids.includes(
                    parseInt(editable.data.Country.id)
                  ) || shop.id == data.granitShopId
                : null
            )"
            :key="option.id"
            :value="option.id"
            :selected="option.id === editable.data.granitShopId"
          >
            {{ option.title }} {{ `(${option.base_domain})` }}
          </option>
        </select>
        <strong class="level"
          ><span>Vertragspartner</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.contractPartnerId, editable.data.contractPartnerId)
            "
            :class="$style.changed"
          />
        </strong>
        <select
          v-if="!editable.form"
          :value="data.contractPartnerId"
          disabled
          class="rg-select"
        >
          <option
            v-for="option in contractPartners"
            :key="option.id"
            :value="option.id"
            :selected="option.id === data.contractPartnerId"
          >
            {{ `${option.country_full} - ` }}
            {{ option.company }}
            {{ `(${option.support_contact})` }}
          </option>
        </select>
        <select
          v-else
          v-model="editable.data.contractPartnerId"
          class="rg-select"
        >
          >
          <option
            v-for="option in contractPartners"
            :key="option.id"
            :value="option.id"
            :selected="option.id === editable.data.contractPartnerId"
          >
            {{ `${option.country_full} - ` }}
            {{ option.company }}
            {{ `(${option.support_contact})` }}
          </option>
        </select>
        <strong class="level"><span>Unternehmensgruppe</span> </strong>
        <select
          v-if="!editable.form"
          :value="data.companyAssociationId"
          disabled
          class="rg-select"
        >
          <option :key="null" :value="null" />
          <option
            v-for="option in companyAssociations"
            :key="option.id"
            :value="option.id"
            :selected="option.id === data.companyAssociationId"
          >
            {{ option.association_title }}
          </option>
        </select>
        <select
          v-else
          v-model="editable.data.companyAssociationId"
          class="rg-select"
        >
          <option :key="null" :value="null" />
          <option
            v-for="option in companyAssociations"
            :key="option.id"
            :value="option.id"
            :selected="option.id === data.companyAssociationId"
          >
            {{ option.association_title }}
          </option>
        </select>
        <strong class="level"
          ><span>Kommentar</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.companyComment, editable.data.companyComment)
            "
            :class="$style.changed"
          />
        </strong>
        <textarea
          v-if="!editable.form"
          :value="data.companyComment"
          disabled
          cols="30"
          rows="10"
        />
        <textarea
          v-else
          v-model="editable.data.companyComment"
          cols="30"
          rows="10"
        />
      </template>
    </rg-widget>
    <rg-widget title="Verknüpfungen" class="w6 form">
      <template #data>
        <strong class="level"
          ><span>Hauptbenutzer</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.managingUserId, editable.data.managingUserId)
            "
            :class="$style.changed"
          />
        </strong>
        <select v-if="!editable.form" disabled class="rg-select">
          <option :key="null" :value="null" />
          <option
            v-for="option in data.Users"
            :key="option.id"
            :selected="option.id === data.managingUserId"
          >
            {{ option.firstName }} {{ option.lastName }} ({{ option.id }})
          </option>
        </select>
        <select v-else v-model="editable.data.managingUserId" class="rg-select">
          >
          <option :key="null" :value="null" />
          <option
            v-for="option in data.Users"
            :key="option.id"
            :value="option.id"
            :selected="option.id === editable.data.managingUserId"
          >
            {{ option.firstName }} {{ option.lastName }} ({{ option.id }})
          </option>
        </select>
        <strong class="level"
          ><span>GRANIT Ansprechpartner Vertrieb</span>
          <span
            v-if="
              editable.form &&
              formDirty(
                data.salesRepresentativeId,
                editable.data.salesRepresentativeId
              )
            "
            :class="$style.changed"
          />
        </strong>
        <select v-if="!editable.form" disabled class="rg-select">
          <option :key="null" :value="null" />
          <option
            v-for="option in salesUsers"
            :key="option.id"
            :selected="option.id === data.salesRepresentativeId"
          >
            {{ option.prename }} {{ option.name }} ({{ option.id }})
          </option>
        </select>
        <select
          v-else
          v-model="editable.data.salesRepresentativeId"
          class="rg-select"
        >
          >
          <option :key="null" :value="null" />
          <option
            v-for="option in salesUsers"
            :key="option.id"
            :value="option.id"
            :selected="option.id === editable.data.salesRepresentativeId"
          >
            {{ option.prename }} {{ option.name }} ({{ option.id }})
          </option>
        </select>
        <hr />
        <h3>Regionale Einstellungen</h3>
        <strong class="level"
          ><span>Land</span>
          <span
            v-if="
              editable.form &&
              formDirty(data.Country.id, editable.data.Country.id)
            "
            :class="$style.changed"
          />
        </strong>
        <select
          v-if="!editable.form"
          :value="data.Country"
          disabled
          class="rg-select"
        >
          <option
            v-for="option in countries"
            :key="option.id"
            :value="option"
            :selected="data.Country ? option.id === data.Country.id : false"
          >
            {{ option.country_name }} ({{ option.country_code }})
          </option>
        </select>
        <select v-else v-model="editable.data.Country" class="rg-select">
          >
          <option
            v-for="option in countries"
            :key="option.id"
            :value="option"
            :selected="option.id === data.Country.id"
          >
            {{ option.country_name }} ({{ option.country_code }})
          </option>
        </select>
        <hr />
        <h3>Support</h3>
        <rg-button
          type="is-primary"
          icon-left="headset"
          label="Ticket erstellen"
          style="margin-bottom: 10px"
          @click="createTicket()"
        />
      </template>
    </rg-widget>
    <rg-widget title="Informationen" class="w6 form">
      <template #data>
        <div class="level">
          <strong><span>Erstellt</span></strong>
          {{
            new Date(data.createdAt).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
        <div class="level">
          <strong><span>Zuletzt aktualisiert</span></strong>
          {{
            new Date(data.updatedAt).toLocaleString("de-DE", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })
          }}
        </div>
        <div class="level">
          <strong><span>Links:</span></strong>
        </div>
        <br />
        <a
          :href="`https://metabase.repguide.de/question/250-alle-rechnungen-eines-kunden?id=${data.id}`"
        >
          Rechnungen
        </a>
      </template>
    </rg-widget>
    <rg-widget title="Jaltest" class="w6 form">
      <template #data>
        Dieses Feature steht aktuell nicht zur Verfügung.
        <!-- <div v-if="data.Jaltest">
          <div class="level">
            <strong><span>License ID</span></strong>
            {{ data.Jaltest.license_id || "" }}
          </div>
          <div class="level">
            <strong><span>Lizennummer</span></strong>
            {{ data.Jaltest.jaltest_license || "" }}
          </div>
          <div class="level">
            <strong><span>Reserviert durch Benutzer ID</span></strong>
            {{ data.Jaltest.assigned_user_id || "" }}
          </div>
          <div class="level">
            <strong><span>Reserviert bis</span></strong>
            {{
              new Date(data.Jaltest.expires).toLocaleString("de-DE", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            }}
          </div>
        </div> -->
      </template>
    </rg-widget>
  </div>
</template>

<script>
import RgWidget from "@/components/elements/rg-widget.vue";
import {
  ref,
  watchEffect,
  onBeforeMount,
  onMounted,
} from "@vue/composition-api";
import { formDirty } from "@/helper.js";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import Vue from "vue";

export default {
  name: "CompanyHome",
  components: {
    RgWidget,
  },
  props: {
    data: { type: Object, default: () => {} },
  },
  setup(props, context) {
    onBeforeMount(() => {
      getCompanyAssociations();
      getShops();
      getContractPartners();
      getCountries();
      getSalesUsers();
    });
    let data = ref({});
    let granitShops = ref([]);
    let companyAssociations = ref([]);
    let contractPartners = ref([]);
    let countries = ref([]);
    let editable = ref({
      loginValidated: null,
      emailValidated: null,
      form: false,
      data: {
        sapId: null,
      },
    });
    let salesUsers = ref([]);
    let managing_user_login = "";
    let existingCompany = ref(null);
    let existingSapID = ref(null);
    let resultFilter = ref({
      step: 25,
      first: 25,
      offset: null,
      search: "",
      loading: true,
      timeout: null,
    });
    let granitIdIsSAPId = ref();
    let showTooltip = ref(false);

    const dispatchSearch = (ms = 700) => {
      clearTimeout(resultFilter.value.timeout);

      resultFilter.value.timeout = setTimeout(() => {
        resultFilter.value.loading = true;
        resultFilter.value.offset = null;
        const gqQueryUserCompany = `query Query($granitID: String) {
        Company(granitId: $granitID) {
            title
        }
      }`;
        superagent
          .post(altBackendUrl)
          .send({
            query: gqQueryUserCompany,
            variables: { granitID: editable.value.data.granitId },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            existingCompany.value = res.body.data.Company;
          })
          .catch((err) => {
            console.log(err);
          });
      }, ms);
      resultFilter.value.loading = false;
    };

    const checkSapId = () => {
      if (
        data.value.sapId != undefined &&
        data.value.granitId === data.value.sapId
      ) {
        granitIdIsSAPId.value = true;
      } else {
        granitIdIsSAPId.value = false;
      }
    };
    onMounted(() => {
      setTimeout(checkSapId, 500);
    });

    function lookupSapId(ms = 1000) {
      clearTimeout(resultFilter.value.timeout);
      if (editable.value.data.sapId == "") {
        existingSapID.value = false;
        return;
      }
      resultFilter.value.timeout = setTimeout(() => {
        resultFilter.value.loading = true;
        resultFilter.value.offset = null;
        const gqQueryCompany = `
        query Query($sapId: String) {
          Company(sapId: $sapId) {
            id
          }
        }
      `;
        superagent
          .post(altBackendUrl)
          .send({
            query: gqQueryCompany,
            variables: { sapId: editable.value.data?.sapId },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((res) => {
            if (
              res?.body?.data?.Company?.id &&
              res.body.data.Company.id != data.value.id
            ) {
              existingSapID.value = true;
            } else {
              existingSapID.value = false;
            }
          });
      }, ms);
      resultFilter.value.loading = false;
    }

    watchEffect(() => {
      data.value = props.data;
    });

    function toggleEdit(val) {
      if (val) {
        editable.value.data = { ...data.value };
      }
    }

    function checkFormInput() {
      if (
        editable.value.data.id &&
        editable.value.data.title &&
        editable.value.data.granitId &&
        editable.value.data.granitShopId &&
        editable.value.data.contractPartnerId &&
        editable.value.data.managingUserId
      )
        return true;
    }

    function saveChanges() {
      const payload = {
        id: parseInt(editable.value.data.id) || null,
        companyTitle: editable.value.data.title || null,
        granitid: editable.value.data.granitId || null,
        granitShopId: parseInt(editable.value.data.granitShopId) || null,
        contractPartnerId:
          parseInt(editable.value.data.contractPartnerId) || null,
        managingUserId: parseInt(editable.value.data.managingUserId) || null,
        salesRepresentativeId:
          parseInt(editable.value.data.salesRepresentativeId) || null,
        companyComment: editable.value.data.companyComment || null,
        countryId: parseInt(editable.value.data.Country.id) || null,
        companyAssociatedWith:
          parseInt(editable.value.data.companyAssociationId) || null,
        sapId: granitIdIsSAPId.value
          ? editable.value.data.granitId
          : editable.value.data.sapId
          ? editable.value.data.sapId
          : null,
      };

      const updateCompanyMutation = `mutation Mutation($companyId: Int, $companyCompanyTitle: String!,
       $companyGranitid: String!, $companyGranitShopId: Int, $companyContractPartnerId: Int,
       $companyManagingUserId: Int, $companySalesRepresentativeId: Int, $companyCompanyComment: String, $countryId: Int, $companyAssociatedWith: Int, $sapId: String) {
        Company(id: $companyId, company_title: $companyCompanyTitle, granitid: $companyGranitid,
          granit_shop_id: $companyGranitShopId, contract_partner_id: $companyContractPartnerId,
          managing_user_id: $companyManagingUserId, sales_representative_id: $companySalesRepresentativeId,
          company_comment: $companyCompanyComment,
          country_id: $countryId, company_associated_with: $companyAssociatedWith, sap_id: $sapId
        ) {
          id
        }
      }`;

      superagent
        .post(altBackendUrl)
        .send({
          query: updateCompanyMutation,
          variables: {
            companyId: payload.id,
            companyCompanyTitle: payload.companyTitle,
            companyGranitid: payload.granitid,
            companyGranitShopId: payload.granitShopId,
            companyContractPartnerId: payload.contractPartnerId,
            companyManagingUserId: payload.managingUserId,
            companySalesRepresentativeId: payload.salesRepresentativeId,
            companyCompanyComment: payload.companyComment,
            countryId: payload.countryId,
            companyAssociatedWith: payload.companyAssociatedWith,
            sapId: payload.sapId,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resCompany) => {
          if (
            !resCompany.body?.data?.errors &&
            resCompany.body?.data?.Company
          ) {
            Vue.$toast.open({
              icon: "check",
              message: "Die Firma wurde erfolgreich gespeichert.",
            });
            setTimeout(() => {
              context.root.$router.go();
            }, 2000);
          } else {
            Vue.$toast.open({
              icon: "times-circle",
              type: "error",
              message:
                "FEHLER: Die Firma konnte nicht gespeichert werden. Die angegebene Kundennummer könnte von einer anderen Firme belegt sein.",
            });
            editable.value.form = false;
            console.table(payload);
            console.log(resCompany.body?.data?.errors);
          }
        })
        .catch((err) => {
          Vue.$toast.open({
            icon: "times-circle",
            type: "error",
            message:
              "FEHLER: Die Firma konnte nicht gespeichert werden. Die angegebene Kundennummer könnte von einer anderen Firme belegt sein.",
          });
          editable.value.form = false;
          console.table(payload);
          console.error(err?.response?.body?.errors);
        });
    }

    function getCompanyAssociations() {
      const gqQueryCompanyAssociation = `
      query CompanyAssociations {
        CompanyAssociations {
          id
          association_title
          association_description
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryCompanyAssociation,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resAssociations) => {
          companyAssociations.value =
            resAssociations.body.data.CompanyAssociations.sort((a, b) =>
              a.association_title.localeCompare(b.association_title)
            );
        });
    }

    function getShops() {
      const gqQueryShops = `
      query GranitShops {
        GranitShops {
          id
          title
          base_domain
          shop_key
          createdat
          updatedat
          country_ids
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryShops,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resShops) => {
          granitShops.value = resShops.body.data.GranitShops.sort((a, b) =>
            a.title > b.title ? 1 : -1
          );
        });
    }

    function getContractPartners() {
      const gqQueryContractPartners = `
      query GranitShops {
        ContractPartners {
          id
          company
          country
          country_full
          support_contact
          country_flag
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryContractPartners,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resContractPartners) => {
          contractPartners.value =
            resContractPartners.body.data.ContractPartners.sort((a, b) =>
              a.country_full > b.country_full ? 1 : -1
            );
        });
    }

    function getCountries() {
      const gqlQueryAppCountries = `
      query Query {
        AppCountries {
          country_code
          country_name
          id
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqlQueryAppCountries,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          countries.value = res.body.data.AppCountries;
        });
    }

    function getSalesUsers() {
      superagent
        .get(
          "https://repguide-rest.fricke-holding.workers.dev/api/legacy/users/by-roles/sales"
        )
        .then((res) => {
          salesUsers.value = res.body.data.Users.sort((a, b) =>
            a.prename > b.prename ? 1 : -1
          );
        });
    }
    function createTicket(subject = "") {
      superagent
        .get(
          `https://repguide-rest.fricke-holding.workers.dev/api/legacy/user/by-id/${data.value.managingUserId}`
        )
        .then((res) => {
          managing_user_login = res.body.data.User.login;
        })
        .finally(() => {
          window.open(
            `https://frixlab.fricke.de/innovationlab/repguide/support/-/issues/new?issue[title]=${data.value.managingUserId}%20-%20${managing_user_login}%20-%20${data.value.granitId}%20-%20${subject}`,
            "_blank"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return {
      existingCompany,
      dispatchSearch,
      toggleEdit,
      editable,
      checkFormInput,
      formDirty,
      granitShops,
      contractPartners,
      countries,
      saveChanges,
      salesUsers,
      createTicket,
      companyAssociations,
      existingSapID,
      lookupSapId,
      granitIdIsSAPId,
      showTooltip,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
