<template>
  <div>
    <navigation />
    <main>
      <div class="rg-grid">
        <div class="level w12">
          <h2>Neue Maschine anlegen</h2>
          <div class="level">
            <rg-button
              tabindex="24"
              type="is-primary"
              icon-left="save"
              label="Speichern"
              :disabled="
                !isValidSlug.valid ||
                isValidSlug.slugConflict ||
                isValidSlug.checking
              "
              @click="saveChanges()"
            />
          </div>
        </div>
        <rg-widget class="w6 s12 l4 form" title="Allgemeines">
          <template #data>
            <strong class="level"><span>Titel</span></strong>
            <input
              tabindex="1"
              :value="machine.title"
              type="text"
              @change="machine.title = $event.target.value.trim()"
            />

            <strong>Slug</strong>
            <a
              @click="
                machine.slug = generateSlug(machine.title);
                checkSlug(machine.slug);
              "
              >aus Titel generieren</a
            >
            <div
              v-if="
                machine.slug &&
                !isValidSlug.checking &&
                (!isValidSlug.valid || isValidSlug.slugConflict)
              "
              class="is-danger"
            >
              <fa icon="exclamation-triangle" class="fa-fw" />
              <span v-if="isValidSlug.slugConflict"
                >Eine Maschine mit diesem Slug existiert bereits</span
              >
              <span v-else>
                Der Slug ist ungültig (enthält Sonderzeichen/Umlaute oder ein
                "-" am Anfang/Ende oder mehrere Sonderzeichen zwischen den
                Charakteren)
              </span>
            </div>
            <input
              v-model="machine.slug"
              tabindex="2"
              type="text"
              :style="!isValidSlug ? 'border-color:red;' : ''"
              @input="checkSlug(machine.slug)"
            />

            <hr />

            <strong class="level"><span>Typ</span></strong>
            <select
              v-model="machine.type"
              class="rg-select"
              @change="getDefaultIndustry"
            >
              <option v-for="type in machineTypes" :key="type.id" :value="type">
                {{ type.titleDe }}
              </option>
            </select>

            <datalist id="manufacturer">
              <option v-for="e in ac.manufacturer" :key="e">
                {{ e }}
              </option>
            </datalist>
            <strong class="level"><span>Hersteller</span></strong>
            <input
              v-model="machine.manufacturer"
              tabindex="4"
              type="text"
              list="manufacturer"
            />

            <datalist id="series">
              <option v-for="e in ac.series" :key="e">
                {{ e }}
              </option>
            </datalist>
            <strong class="level"><span>Serie</span></strong>
            <input
              v-model="machine.series"
              tabindex="5"
              type="text"
              list="series"
            />

            <strong class="level"><span>Modell</span></strong>
            <input v-model="machine.model" tabindex="6" type="text" />

            <strong class="level"
              ><span>Fahrzeugidentifikationsnummer</span></strong
            >
            <input v-model="machine.vin" tabindex="7" type="text" />

            <strong class="level"><span>Region</span></strong>
            <input v-model="machine.region" tabindex="8" type="text" />

            <strong class="level"><span>Industrie</span></strong>
            <select v-model="machine.industry" class="rg-select">
              <option
                v-for="industry in machineIndustries"
                :key="industry.id"
                :value="industry"
              >
                {{ industry.industry_title }}
              </option>
            </select>
            <strong class="level"><span>Markt</span></strong>
            <select v-model="machine.market" class="rg-select">
              <option
                v-for="market in machineMarkets"
                :key="market.id"
                :value="market"
              >
                {{ market.market_title }}
              </option>
            </select>

            <hr />

            <div class="rg-grid">
              <div class="w6">
                <strong class="level"><span>Sprache (Quelle)</span></strong>
                <input v-model="machine.language" tabindex="9" type="text" />
              </div>
              <div class="w6">
                <strong class="level"><span>Sprache (Eingabe)</span></strong>
                <input v-model="machine.language" tabindex="10" type="text" />
              </div>
            </div>
            <span style="opacity: 0.5"
              ><fa icon="info" class="fa-fw" />Hat aktuell noch den gleichen
              Wert</span
            >
          </template>
        </rg-widget>
        <div class="w6 s12 l4">
          <rg-widget class="w6 s12 l4 form" title="Motor">
            <template #data>
              <datalist id="engine">
                <option v-for="e in ac.engine" :key="e">
                  {{ e }}
                </option>
              </datalist>
              <strong class="level"><span>Motortyp</span></strong>
              <input
                v-model="machine.engine"
                tabindex="11"
                type="text"
                list="engine"
              />

              <datalist id="transmission">
                <option v-for="e in ac.transmission" :key="e">
                  {{ e }}
                </option>
              </datalist>
              <strong class="level"><span>Getriebe</span></strong>
              <input
                v-model="machine.transmission"
                tabindex="12"
                type="text"
                list="transmission"
              />

              <datalist id="emission">
                <option v-for="e in ac.emission" :key="e">
                  {{ e }}
                </option>
              </datalist>
              <strong class="level"><span>Emission</span></strong>
              <input
                v-model="machine.emission"
                tabindex="13"
                type="text"
                list="emission"
              />
            </template>
          </rg-widget>
          <br />
          <rg-widget class="w6 s12 l4 form" title="Produktion">
            <template #data>
              <div class="rg-grid">
                <div class="w6">
                  <strong class="level"
                    ><span
                      ><abbr title="Fahrgestellnummer">Fgst.-Nr.</abbr>
                      von</span
                    ></strong
                  >
                  <input
                    v-model="machine.chassisnrstart"
                    tabindex="14"
                    type="text"
                  />
                </div>
                <div class="w6">
                  <strong class="level"
                    ><span
                      ><abbr title="Fahrgestellnummer">Fgst.-Nr.</abbr>
                      bis</span
                    ></strong
                  >
                  <input
                    v-model="machine.chassisnrend"
                    tabindex="15"
                    type="text"
                  />
                </div>
              </div>

              <hr />

              <div class="rg-grid">
                <div class="w6">
                  <strong class="level"><span>Baujahr von</span></strong>
                  <input
                    v-model.number="machine.yearstart"
                    tabindex="16"
                    type="text"
                    @keypress="onlyInteger($event)"
                  />
                </div>
                <div class="w6">
                  <strong class="level"><span>Baujahr bis</span></strong>
                  <input
                    v-if="!machine.yeartoday"
                    v-model.number="machine.yearend"
                    tabindex="17"
                    type="text"
                    @keypress="onlyInteger($event)"
                  />
                  <input
                    v-else
                    tabindex="17"
                    :value="machine.yeartoday ? 'heute' : machine.yearend"
                    disabled
                    type="text"
                  />
                </div>
              </div>

              <div class="level">
                <strong class="level"
                  ><span
                    ><fa
                      class="fa-fw"
                      style="opacity: 0.5"
                      icon="calendar"
                    />&nbsp;Wird noch hergestellt</span
                  ></strong
                >
                <label class="switch" style="margin-right: 5px">
                  <input
                    ref="chechyear"
                    v-model="machine.yeartoday"
                    tabindex="18"
                    type="checkbox"
                    @keypress.enter="$refs['chechyear'].click()"
                  />
                  <span class="slider" />
                </label>
              </div>
              <br />
            </template>
          </rg-widget>
          <br />
          <rg-widget class="w6 s12 l4 form" title="Vererbung">
            <template #data>
              <div class="rg-grid">
                <div class="w6">
                  <strong class="level"><span>Parent-ID</span></strong>
                  <input
                    v-model.number="machine.parentid"
                    tabindex="19"
                    type="number"
                    @keypress="onlyInteger($event)"
                  />
                </div>
                <div class="w6">
                  <strong class="level"><span>Vererbung nach</span></strong>
                  <select
                    v-model="machine.descentby"
                    tabindex="20"
                    class="rg-select"
                  >
                    <option value="year">Baujahr</option>
                    <option value="chassis">Fahrgestellnummer</option>
                    <option value="emission">Abgasnorm</option>
                    <option value="engine">Motor</option>
                    <option value="transmission">Getriebe</option>
                  </select>
                </div>
              </div>
            </template>
          </rg-widget>
        </div>
        <div class="w6 s12 l4">
          <rg-widget class="form" title="Redaktion">
            <template #data>
              <strong class="level"><span>Gitlab Ticket</span></strong>
              <input
                v-model.number="machine.gitlabticket"
                tabindex="21"
                type="number"
                @keypress="onlyInteger($event)"
              />

              <strong class="level"><span>Granit-ID</span></strong>
              <input
                v-model.number="machine.granitid"
                tabindex="22"
                type="number"
                @keypress="onlyInteger($event)"
              />

              <strong class="level"
                ><span>Stand der technischen Daten</span></strong
              >
              <input
                v-model="machine.origindatetechdata"
                tabindex="23"
                type="text"
                @keypress="onlyInteger($event, '/')"
              />
            </template>
          </rg-widget>
          <br />
          <rg-widget class="form" title="Daten">
            <template #data>
              <strong class="level"
                ><span>Status (Maschine aktiviert)</span></strong
              >
              <select v-model="machine.status" tabindex="25" class="rg-select">
                <option value="enabled">Aktiviert</option>
                <option value="disabled">Deaktiviert</option>
                <option value="blocked">Blockiert</option>
              </select>
              <br />
              <div class="level">
                <strong class="level"
                  ><span
                    ><fa
                      class="fa-fw"
                      style="opacity: 0.5"
                      icon="server"
                    />&nbsp;Technische Daten vorhanden</span
                  ></strong
                >
                <label class="switch" style="margin-right: 5px">
                  <input
                    ref="chechtech"
                    v-model="machine.hastechdata"
                    tabindex="26"
                    type="checkbox"
                    @keypress.enter="$refs['chechtech'].click()"
                  />
                  <span class="slider" />
                </label>
              </div>
              <br />
              <div class="level">
                <strong class="level"
                  ><span
                    ><fa
                      class="fa-fw"
                      style="opacity: 0.5"
                      icon="cogs"
                    />&nbsp;Komponenten & Teile verknüpft</span
                  ></strong
                >
                <label class="switch" style="margin-right: 5px">
                  <input
                    ref="checkpart"
                    v-model="machine.hasparts"
                    tabindex="27"
                    type="checkbox"
                    @keypress.enter="$refs['checkpart'].click()"
                  />
                  <span class="slider" />
                </label>
              </div>
              <br />
              <div class="level">
                <strong class="level"
                  ><span
                    ><fa
                      class="fa-fw"
                      style="opacity: 0.5"
                      icon="clipboard-check"
                    />&nbsp;Service Plan hinterlegt</span
                  ></strong
                >
                <label class="switch" style="margin-right: 5px">
                  <input
                    ref="checkplan"
                    v-model="machine.hasserviceplan"
                    tabindex="28"
                    type="checkbox"
                    @keypress.enter="$refs['checkplan'].click()"
                  />
                  <span class="slider" />
                </label>
              </div>
              <br />
            </template>
          </rg-widget>
        </div>
        <div class="w6 s12 l4">
          <div
            class="card w6"
            style="border-radius: 10px; padding: 20px; margin-bottom: 10px"
          >
            <h3>Erweiterungs-Berechtigungen</h3>
            <br />
            <div
              v-for="extension in extensions"
              :key="extension.id"
              style="display: flex; gap: 10px"
            >
              <div>
                <label class="switch" style="margin-right: 5px">
                  <input
                    type="checkbox"
                    :checked="
                      permittedExtensions &&
                      permittedExtensions.includes(extension.id)
                    "
                    @change="changePermission(extension.id)"
                  />
                  <span class="slider" />
                </label>
              </div>
              <div>
                <div>
                  <strong>
                    <span>{{ extension.extension_name }}</span>
                  </strong>
                </div>
                <div v-if="extension.extension_description">
                  {{ extension.extension_description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { ref, onMounted } from "@vue/composition-api";
import Vue from "vue";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import { onlyInteger, onlyAlphaNumeric, es, generateSlug } from "@/helper.js";

export default {
  name: "Detail",
  components: {
    rgWidget: () => import("@/components/elements/rg-widget.vue"),
  },
  beforeRouteLeave(to, from, next) {
    next(window.confirm("Formular wirklich verlassen?"));
  },
  setup(props, context) {
    const getRelevant = Symbol();

    onMounted(async () => {
      fetchExtensions();
      changePermission(1);
      getMarketsAndIndustries();
    });

    let ac = ref({
      manufacturer: [],
      series: [],
      engine: [],
      transmission: [],
      emission: [],
      type: [],
    });

    let machine = ref({
      slug: null,
      type: null,
      title: null,
      manufacturer: null,
      series: null,
      model: null,
      parentid: null,
      vin: null,
      granitid: null,
      gitlabticket: null,
      origindatetechdata: null,
      chassisnrstart: null,
      chassisnrend: null,
      engine: null,
      transmission: null,
      emission: null,
      urlmanufacturer: null,
      urlwikibooks: null,
      urlkonedata: null,
      urltraktorbook: null,
      comment: null,
      hastechdata: false,
      hasserviceplan: false,
      hasparts: false,
      status: "disabled",
      descentby: null,
      language: "de-DE",
      region: "EU",
      yearstart: null,
      yearend: null,
      yeartoday: false,
      default_industry_id: "",
      defaultMarketTitle: "European Union vendor market",
    });

    let machineMarkets = ref([]);
    let machineIndustries = ref([]);

    let machineTypes = ref({});

    let sources = ref({
      document: { src: "", lang: "de-DE" },
      sharepoint: { src: "", lang: "de-DE" },
      manufacturer: { src: "", lang: "de-DE" },
      [getRelevant]: () => {
        let obj = {};
        if (sources.value.document.src.length) {
          obj.document = { ...sources.value.document };
        }
        if (sources.value.sharepoint.src.length) {
          obj.sharepoint = { ...sources.value.sharepoint };
        }
        if (sources.value.manufacturer.src.length) {
          obj.manufacturer = { ...sources.value.manufacturer };
        }
        return Object.keys(obj).length === 0 && obj.constructor === Object
          ? null
          : obj;
      },
    });
    let languages = ["de-DE", "en-EN", "fr-FR", "es-ES", "it-IT"];
    let extensions = ref([]);
    let permittedExtensions = ref([]);
    let isValidSlug = ref({
      valid: false,
      slugConflict: false,
      checking: false,
    });
    let validationTimeoutId = null;

    const checkSlugFormat = (slug) => {
      return (
        !RegExp(/^[\W_]+|[\W_]+$/).test(slug) &&
        !RegExp(/\u00c4|\u00e4|\u00d6|\u00f6|\u00dc|\u00fc|\u00df/g).test(
          slug
        ) &&
        !RegExp(/--+|_/g).test(slug) &&
        !RegExp(/[^a-zA-Z0-9-]+/).test(slug)
      );
    };

    async function checkSlug(slug, ms = 500) {
      isValidSlug.value.checking = true;
      isValidSlug.value.valid = false;
      isValidSlug.value.slugConflict = false;
      clearTimeout(validationTimeoutId);
      const validationResponse = await new Promise((resolve) => {
        validationTimeoutId = setTimeout(() => {
          if (!checkSlugFormat(slug)) {
            resolve(false);
          } else {
            isValidSlug.value.valid = true;
            const gqQueryMachine = `
            query Machine($slug: String!) {
              Machine(slug: $slug) {
                id
                slug
              }
            }
            `;
            superagent
              .post(altBackendUrl)
              .send({
                query: gqQueryMachine,
                variables: {
                  slug: slug,
                },
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              )
              .then((res) => {
                isValidSlug.value.slugConflict =
                  res.body?.data?.Machine?.id &&
                  res.body?.data?.Machine?.id !== machine.value.id;
                if (isValidSlug.value.slugConflict) {
                  resolve(false);
                }
                resolve(true);
              });
          }
        }, ms);
      });
      isValidSlug.value.checking = false;
      return validationResponse;
    }

    getMachineTypes();

    superagent
      .post(es + "/repguide_machines/_search", {
        query: { match_all: {} },
        size: 0,
        aggs: {
          manufacturer: {
            terms: {
              field: "manufacturer.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          series: {
            terms: {
              field: "series.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          engine: {
            terms: {
              field: "engine.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          transmission: {
            terms: {
              field: "transmission.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          emission: {
            terms: {
              field: "emission.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          type: {
            terms: {
              field: "type.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
        },
      })
      .set(
        "Authorization",
        "ApiKey SHRMYXBYc0I2YjNKWVpPcjlXVG06YXdOcEpTQ0JTYUMyRktKajdOZGxmQQ=="
      )
      .then((res) => {
        for (const key in ac.value) {
          ac.value[key] = res.body?.aggregations?.[key].buckets.map(
            (b) => b.key
          );
        }
      });

    function getMarketsAndIndustries() {
      const gqlQueryMarketsAndIndustries = `
          query MachineMarket {
            MachineMarkets {
              i18n_key
              id
              market_title
            }
            MachineIndustries {
              i18n_key
              id
              industry_title
            }
          }
          `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqlQueryMarketsAndIndustries,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          machineMarkets.value = res.body?.data.MachineMarkets;
          machineIndustries.value = res.body?.data.MachineIndustries;
          getDefaultMarket();
        });
    }

    async function getDefaultMarket() {
      machine.value.market = machineMarkets.value.find((obj) => {
        return obj.market_title === machine.value.defaultMarketTitle;
      });
    }
    function getDefaultIndustry() {
      machine.value.industry = machineIndustries.value.find(
        (obj) => obj.id === machine.value.type.default_industry_id
      );
    }

    function getMachineTypes() {
      const gqQueryMachineTypes = `
        query MachineTypes {
          MachineTypes {
            default_industry_id
            id
            descriptionDe
            key
            titleDe
          }
        }
        `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryMachineTypes,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          machineTypes.value = res.body?.data?.MachineTypes;
        });
    }

    function showDevMessage(logPayload = undefined) {
      if (logPayload) console.table(logPayload);
      Vue.$toast.open({
        icon: "check",
        message:
          "Maschine erfolgreich gespeichert. Im Entwicklungsmodus wird die Maschine nicht in die Suche importiert und die Seite nicht automatisch neu geladen.",
        dismissable: true,
        duration: 30_000,
      });
    }

    // MARK - add machine
    function saveChanges() {
      if (!checkSlug(machine.value.slug, 0)) {
        Vue.$toast.open({
          message: "Der Slug ist ungültig, bitte prüfe Deine Eingabe.",
          icon: "exclamation",
        });
        return;
      }
      const payload = {
        authorid: parseInt(context.root.$store.state.user.id),

        slug: machine.value.slug?.trim(),

        type: machine.value.type.id,
        typeId: machineTypes.value.filter((el) => {
          return el.key === machine.value.type.key;
        })[0]?.id,
        title: machine.value.title?.trim(),

        manufacturer: machine.value.manufacturer?.trim(),
        series: machine.value.series?.trim() || null,
        model: machine.value.model?.trim() || null,

        parentid: parseInt(machine.value.parentid) || null,
        vin: machine.value.vin?.trim() || null,
        granitid: parseInt(machine.value.granitid) || null,
        gitlabticket: parseInt(machine.value.gitlabticket) || null,
        origindatetechdata: machine.value.origindatetechdata?.trim() || null,

        chassisnrstart: machine.value.chassisnrstart?.trim() || null,
        chassisnrend: machine.value.chassisnrend?.trim() || null,

        engine: machine.value.engine?.trim() || null,
        transmission: machine.value.transmission?.trim() || null,
        emission: machine.value.emission?.trim() || null,

        urlmanufacturer: machine.value.urlmanufacturer?.trim() || null,
        urlwikibooks: machine.value.urlwikibooks?.trim() || null,
        urlkonedata: machine.value.urlkonedata?.trim() || null,
        urltraktorbook: machine.value.urltraktorbook?.trim() || null,
        comment: machine.value.comment || null,

        // data_sources: sources[getRelevant]() || null,
        hastechdata: machine.value.hastechdata ? true : false,
        hasserviceplan: machine.value.hasserviceplan ? true : false,
        hasparts: machine.value.hasparts ? true : false,
        status: machine.value.status?.trim(),
        descentby: machine.value.descentby?.trim() || null,

        language: machine.value.language?.trim() || "de-DE",
        region: machine.value.region?.trim() || "EU",

        yearstart: parseInt(machine.value.yearstart) || null,
        yearend: machine.value.yeartoday
          ? null
          : parseInt(machine.value.yearend) || null,

        market: machine.value.market?.id || null,
        industry: machine.value.industry?.id || null,
      };

      if (payload.yearstart || payload.yearend || machine.value.yeartoday) {
        payload.yearshort =
          (payload.yearstart
            ? payload.yearstart?.toString().slice(-2) + "-"
            : "Bis ") +
          (machine.value.yeartoday
            ? "heute"
            : payload.yearend?.toString().slice(-2) || "");
        payload.year =
          (payload.yearstart ? payload.yearstart + "-" : "Bis ") +
          (machine.value.yeartoday ? "heute" : payload.yearend || "");
      } else {
        payload.year = null;
        payload.yearshort = null;
      }

      const gqCreateMachineMutation = `
        mutation Machine($machineId: Int!, $authorid: Int, $slug: String, $type: Int, $title: String, $manufacturer: String,
        $series: String, $model: String, $parentid: Int, $vin: String, $granitid: Int, $gitlabticket: Int, $origindatetechdata: String,
        $chassisnrstart: String, $chassisnrend: String, $engine: String, $transmission: String, $emission: String, $urlmanufacturer: String,
        $urlwikibooks: String, $urlkonedata: String, $urltraktorbook: String, $comment: String, $hastechdata: Boolean,
        $hasserviceplan: Boolean, $hasparts: Boolean, $status: String, $descentby: String, $language: String, $region: String,
        $yearstart: Int, $yearend: Int, $year: String, $yearshort: String, $machineIndustries: [String!], $machineMarkets: [String!]) {
          Machine(id: $machineId, authorid: $authorid, slug: $slug, type: $type, title: $title, manufacturer: $manufacturer, series: $series,
          model: $model, parentid: $parentid, vin: $vin, granitid: $granitid, gitlabticket: $gitlabticket,
          origindatetechdata: $origindatetechdata, chassisnrstart: $chassisnrstart, chassisnrend: $chassisnrend, engine: $engine,
          transmission: $transmission, emission: $emission, urlmanufacturer: $urlmanufacturer, urlwikibooks: $urlwikibooks,
          urlkonedata: $urlkonedata, urltraktorbook: $urltraktorbook, comment: $comment, hastechdata: $hastechdata,
          hasserviceplan: $hasserviceplan, hasparts: $hasparts, status: $status, descentby: $descentby, language: $language, region: $region,
          yearstart: $yearstart, yearend: $yearend, year: $year, yearshort: $yearshort, machine_industries: $machineIndustries, machine_markets: $machineMarkets) {
            id
          }
        }
`;

      const gqMachineAttributesView = `
              query qMachineAttributes($id: Int!) {
              Machine(id: $id) {
                Attributes {
                value
          }
        }
      }
              `;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqCreateMachineMutation,
          variables: {
            machineId: -1,
            authorid: payload.authorid,
            slug: payload.slug,
            type: payload.typeId,
            title: payload.title,
            manufacturer: payload.manufacturer,
            series: payload.series,
            model: payload.model,
            parentid: payload.parentid,
            vin: payload.vin,
            granitid: payload.granitid,
            gitlabticket: payload.gitlabticket,
            origindatetechdata: payload.origindatetechdata,
            chassisnrstart: payload.chassisnrstart,
            chassisnrend: payload.chassisnrend,
            engine: payload.engine,
            transmission: payload.transmission,
            emission: payload.emission,
            urlmanufacturer: payload.urlmanufacturer,
            urlwikibooks: payload.urlwikibooks,
            urlkonedata: payload.urlkonedata,
            urltraktorbook: payload.urltraktorbook,
            comment: payload.comment,
            hastechdata: payload.hastechdata,
            hasserviceplan: payload.hasserviceplan,
            hasparts: payload.hasparts,
            status: payload.status,
            descentby: payload.descentby,
            language: payload.language,
            region: payload.region,
            yearstart: payload.yearstart,
            yearend: payload.yearend,
            year: payload.year,
            yearshort: payload.yearshort,
            machineIndustries: [payload.industry],
            machineMarkets: [payload.market],
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(async (resMachine) => {
          const machineid = resMachine.body.data.Machine.id;
          const sorted_extensions = permittedExtensions.value.sort(
            (a, b) => a - b
          );
          //Add extension permission
          const mSavePermissionsMutation = `
                mutation Mutation($appPermissionId: Int!, $relationType: String, $relationId: Int, $permittedExtensions: [Int!]) {
                  AppPermission(id: $appPermissionId, relation_type: $relationType, relation_id: $relationId, permitted_extensions: $permittedExtensions) {
                    id
                  }
                }
                `;
          const { body } = await superagent

            .post(altBackendUrl)

            .send({
              query: gqMachineAttributesView,

              variables: {
                id: machineid,
              },
            })
            .set(
              "Authorization",
              `Bearer ${context.root.$store.state.user.apiToken}`
            );

          superagent
            .post(altBackendUrl)
            .send({
              query: mSavePermissionsMutation,
              variables: {
                appPermissionId: -1,
                relationType: "machines",
                relationId: machineid,
                permittedExtensions: sorted_extensions,
              },
            })
            .set(
              "Authorization",
              `Bearer ${context.root.$store.state.user.apiToken}`
            )
            .then(() => {
              Vue.$toast.open({
                message: "Die Berechtigungen wurden erfolgreich gespeichert.",
                icon: "check",
              });
            })
            .catch((err) => {
              Vue.$toast.open({
                message: `Fehler beim Speichern der Berechtigungen: ${err}`,
                icon: "exclamation",
              });
            });

          //ES import

          const hierarchy = {
            join: payload.parentid
              ? { name: "child", parent: payload.parentid?.toString() }
              : { name: "parent" },
            routing: payload.parentid
              ? payload.parentid?.toString()
              : machineid.toString(),
          };

          const contents = [];
          if (payload.hasserviceplan) contents.push("hasserviceplan");
          if (payload.hastechdata) contents.push("hastechdata");
          if (payload.hasparts) contents.push("hasparts");

          const espayload = {
            id: machineid,
            parentid: payload.parentid,
            isparent: payload.isparent,
            hierarchy: hierarchy.join,
            slug: payload.slug,
            status: payload.status,
            title: payload.title,
            manufacturer: payload.manufacturer,
            type: payload.type,
            series: payload.series,
            model: payload.model,
            granitid: payload.granitid,
            gitlabticket: payload.gitlabticket,
            engine: payload.engine,
            transmission: payload.transmission,
            emission: payload.emission,
            hastechdata: payload.hastechdata,
            hasserviceplan: payload.hasserviceplan,
            hasparts: payload.hasparts,
            chassisnrstart: payload.chassisnrstart,
            chassisnrend: payload.chassisnrend,
            yearstart: payload.yearstart,
            yearend: payload.yearend,
            origindatetechdata: payload.origindatetechdata,
            language: payload.language,
            region: payload.region,
            contents,
            attributes: body.data.Machine.Attributes,
            machine_industries: [
              machineIndustries.value.find(
                (industry) => industry.id === payload.industry
              ).i18n_key || "",
            ],
            machine_markets: [
              machineMarkets.value.find(
                (market) => market.id === payload.market
              ).i18n_key || "",
            ],
          };

          // Handling of ES in dev mode
          if (process.env.NODE_ENV === "development") {
            showDevMessage(espayload);
            return;
          }

          superagent
            .post(
              es +
                "/repguide_machines/_create/" +
                machineid +
                "?routing=" +
                hierarchy.routing
            )
            .set(
              "Authorization",
              "ApiKey SHRMYXBYc0I2YjNKWVpPcjlXVG06YXdOcEpTQ0JTYUMyRktKajdOZGxmQQ=="
            )
            .send(espayload)
            .then(() => {
              Vue.$toast.open({
                icon: "check",
                message:
                  "Maschine erfolgreich gespeichert und in die Suche importiert.",
              });
              Vue.$toast.open({
                duration: 10000,
                icon: "external-link-alt",
                message:
                  "Klicke hier um die Maschine " + machineid + " jetzt öffnen",
                onClick: () => {
                  context.root.$router.push("/machines/" + machineid + "/type");
                },
              });
              Vue.$toast.open({
                duration: 10000,
                icon: "eraser",
                message: "Klicke hier um das Formlar zu leeren",
                onClick: () => {
                  machine.value = {
                    slug: null,
                    type: null,
                    title: null,
                    manufacturer: null,
                    series: null,
                    model: null,
                    parentid: null,
                    vin: null,
                    granitid: null,
                    gitlabticket: null,
                    origindatetechdata: null,
                    chassisnrstart: null,
                    chassisnrend: null,
                    engine: null,
                    transmission: null,
                    emission: null,
                    urlmanufacturer: null,
                    urlwikibooks: null,
                    urlkonedata: null,
                    urltraktorbook: null,
                    comment: null,
                    hastechdata: null,
                    hasserviceplan: null,
                    hasparts: null,
                    status: "disabled",
                    descentby: null,
                    language: "de-DE",
                    region: "EU",
                    yearstart: null,
                    yearend: null,
                    yeartoday: null,
                    machine_markets: [],
                    machine_industries: [],
                  };
                },
              });
            })
            .catch((error) => {
              console.trace(error);
              Vue.$toast.open({
                duration: 60000,
                icon: "exclamation",
                type: "error",
                message:
                  "Die Maschie wurde erfolgreich gespeichert, konnte jedoch nicht in die Suche importiert werden.",
              });
              Vue.$toast.open({
                duration: 60000,
                icon: "info",
                message:
                  "Um den Import in die Suche zu wiederholen, öffne die Maschine und speichere die Typenstruktur erneut.",
              });
              Vue.$toast.open({
                duration: 60000,
                icon: "tractor",
                message:
                  "Hier klicken um die Maschine #" +
                  machineid +
                  " jetzt zu öffnen",
                onClick: () => {
                  context.root.$router.push("/machines/" + machineid + "/type");
                },
              });
            });
        })
        .catch((error) => {
          console.trace(error);
          Vue.$toast.open({
            duration: 10000,
            icon: "exclamation",
            type: "error",
            message:
              "Beim Speichern der Maschine ist ein Fehler aufgetreten. Bitte überprüfe deine Eingabe und versuche es erneut.",
          });
        });
    }

    function fetchExtensions() {
      superagent
        .get(
          "https://repguide-rest.fricke-holding.workers.dev/api/legacy/extensions"
        )
        .then(({ body: extensionList }) => {
          extensions.value = extensionList.data.Extensions;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function changePermission(id) {
      if (!permittedExtensions.value.includes(id)) {
        permittedExtensions.value.push(id);
      } else {
        permittedExtensions.value.splice(
          permittedExtensions.value.indexOf(id),
          1
        );
      }
    }

    return {
      sources,
      ac,
      languages,
      machine,
      onlyInteger,
      onlyAlphaNumeric,
      saveChanges,
      generateSlug,
      extensions,
      permittedExtensions,
      changePermission,
      machineTypes,
      isValidSlug,
      getDefaultMarket,
      getDefaultIndustry,
      machineIndustries,
      machineMarkets,
      checkSlug,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
