<template>
  <div class="rg-grid">
    <div class="level w12">
      <h2>Typenstruktur bearbeiten</h2>
      <div class="level">
        <span>Bearbeitung</span>
        <label class="switch" style="margin: 0 5px 0 10px">
          <input
            v-model="editable.form"
            type="checkbox"
            @change="toggleEdit($event)"
          />
          <span class="slider" />
        </label>
        <rg-button
          type="is-primary"
          :disabled="
            !editable.form ||
            (isValidSlug.scope === 'change' &&
              (!isValidSlug.valid ||
                isValidSlug.slugConflict ||
                isValidSlug.checking))
          "
          icon-left="save"
          label="Speichern"
          @click="saveChanges()"
        />
        <rg-button
          style="margin-left: 5px"
          icon-left="copy"
          label="Duplizieren"
          @click="duplicate.toggleModal()"
        />
      </div>
    </div>
    <rg-widget class="w6 s12 l4 form" title="Allgemeines">
      <template #data>
        <strong class="level"
          ><span>Titel</span
          ><span v-if="dirty('title')" :class="$style.changed"
        /></strong>
        <input
          v-if="editable.form"
          v-model="editable.machine.title"
          type="text"
        />
        <input v-else :value="machine.title" disabled type="text" />

        <strong class="level"
          ><span>Slug</span
          ><span v-if="dirty('slug')" :class="$style.changed" />
        </strong>
        <div
          v-if="
            editable.form &&
            isValidSlug.scope === 'change' &&
            (!isValidSlug.valid || isValidSlug.slugConflict) &&
            !isValidSlug.checking
          "
          class="is-danger"
        >
          <span v-if="isValidSlug.slugConflict"
            >Eine Maschine mit diesem Slug existiert bereits</span
          >
          <span v-else>
            Der Slug ist ungültig (enthält Sonderzeichen/Umlaute oder ein "-" am
            Anfang/Ende oder mehrere Sonderzeichen zwischen den Charakteren)
          </span>
        </div>
        <input
          v-if="editable.form"
          v-model="editable.machine.slug"
          type="text"
          :style="
            !isValidSlug.valid &&
            isValidSlug.scope === 'change' &&
            !isValidSlug.checking
              ? 'border-color:red;'
              : ''
          "
          @input="checkSlug(editable.machine.slug)"
          @blur="checkSlug(editable.machine.slug)"
          @keypress.enter="checkSlug(editable.machine.slug)"
        />
        <input v-else :value="machine.slug" disabled type="text" />

        <hr />

        <strong class="level"
          ><span>Typ</span><span v-if="dirty('type')" :class="$style.changed"
        /></strong>

        <select
          v-model="editable.machine.type"
          class="rg-select"
          :disabled="!editable.form"
          @change="getDefaultIndustry"
        >
          <option v-for="type in machineTypes" :key="type.id" :value="type.key">
            {{ type.titleDe }}
          </option>
        </select>

        <datalist id="manufacturer">
          <option v-for="e in ac.manufacturer" :key="e">
            {{ e }}
          </option>
        </datalist>
        <strong class="level"
          ><span>Hersteller</span
          ><span v-if="dirty('manufacturer')" :class="$style.changed"
        /></strong>
        <input
          v-if="editable.form"
          v-model="editable.machine.manufacturer"
          type="text"
          list="manufacturer"
        />
        <input v-else :value="machine.manufacturer" disabled type="text" />

        <datalist id="series">
          <option v-for="e in ac.series" :key="e">
            {{ e }}
          </option>
        </datalist>
        <strong class="level"
          ><span>Serie</span
          ><span v-if="dirty('series')" :class="$style.changed"
        /></strong>
        <input
          v-if="editable.form"
          v-model="editable.machine.series"
          type="text"
          list="series"
        />
        <input v-else :value="machine.series" disabled type="text" />

        <strong class="level"
          ><span>Modell</span
          ><span v-if="dirty('model')" :class="$style.changed"
        /></strong>
        <input
          v-if="editable.form"
          v-model="editable.machine.model"
          type="text"
        />
        <input v-else :value="machine.model" disabled type="text" />

        <strong class="level"
          ><span>Fahrzeugidentifikationsnummer</span
          ><span v-if="dirty('vin')" :class="$style.changed"
        /></strong>
        <input
          v-if="editable.form"
          v-model="editable.machine.vin"
          type="text"
        />
        <input v-else :value="machine.vin" disabled type="text" />

        <hr />

        <strong class="level"
          ><span>Region</span
          ><span v-if="dirty('region')" :class="$style.changed"
        /></strong>
        <input
          v-if="editable.form"
          v-model="editable.machine.region"
          type="text"
        />
        <input v-else :value="machine.region" disabled type="text" />

        <strong class="level"><span>Industrie</span></strong>
        <select
          v-model="editable.machine.machine_industries[0].id"
          :disabled="!editable.form"
          class="rg-select"
        >
          <option
            v-for="industry in machineIndustries"
            :key="industry.id"
            :value="industry.id"
          >
            {{ industry.industry_title }}
          </option>
        </select>
        <strong class="level"><span>Markt</span></strong>
        <select
          v-model="editable.machine.machine_markets[0].id"
          class="rg-select"
          :disabled="!editable.form"
        >
          <option
            v-for="market in machineMarkets"
            :key="market.id"
            :value="market.id"
          >
            {{ market.market_title }}
          </option>
        </select>

        <div class="rg-grid">
          <div class="w6">
            <strong class="level"
              ><span>Sprache (Quelle)</span
              ><span v-if="dirty('language')" :class="$style.changed"
            /></strong>
            <input
              v-if="editable.form"
              v-model="editable.machine.language"
              type="text"
            />
            <input v-else :value="machine.language" disabled type="text" />
          </div>
          <div class="w6">
            <strong class="level"
              ><span>Sprache (Eingabe)</span
              ><span v-if="dirty('language')" :class="$style.changed" /><fa
                icon="info"
                class="fa-fw"
                style="opacity: 0.5"
                title="Wird automatisch an Hand der Dateneingabe gesetzt"
            /></strong>
            <input
              :value="
                machine.Data.originalfallbacklanguage ||
                'Keine Sprache erkannt (keine Version live?)'
              "
              disabled
              type="text"
            />
          </div>
        </div>
      </template>
    </rg-widget>
    <div class="w6 s12 l4">
      <rg-widget class="form" title="Motor">
        <template #data>
          <datalist id="engine">
            <option v-for="e in ac.engine" :key="e">
              {{ e }}
            </option>
          </datalist>
          <strong class="level"
            ><span>Motortyp</span
            ><span v-if="dirty('engine')" :class="$style.changed"
          /></strong>
          <input
            v-if="editable.form"
            v-model="editable.machine.engine"
            type="text"
            list="engine"
          />
          <input v-else :value="machine.engine" disabled type="text" />

          <datalist id="transmission">
            <option v-for="e in ac.transmission" :key="e">
              {{ e }}
            </option>
          </datalist>
          <strong class="level"
            ><span>Getriebe</span
            ><span v-if="dirty('transmission')" :class="$style.changed"
          /></strong>
          <input
            v-if="editable.form"
            v-model="editable.machine.transmission"
            type="text"
            list="transmission"
          />
          <input v-else :value="machine.transmission" disabled type="text" />

          <datalist id="emission">
            <option v-for="e in ac.emission" :key="e">
              {{ e }}
            </option>
          </datalist>
          <strong class="level"
            ><span>Emission</span
            ><span v-if="dirty('emission')" :class="$style.changed"
          /></strong>
          <input
            v-if="editable.form"
            v-model="editable.machine.emission"
            type="text"
            list="emission"
          />
          <input v-else :value="machine.emission" disabled type="text" />
        </template>
      </rg-widget>
      <br />
      <rg-widget class="w6 s12 l4 form" title="Produktion">
        <template #data>
          <div class="rg-grid">
            <div class="w6">
              <strong class="level"
                ><span
                  ><abbr title="Fahrgestellnummer">Fgst.-Nr.</abbr> von</span
                ><span v-if="dirty('chassisStart')" :class="$style.changed"
              /></strong>
              <input
                v-if="editable.form"
                v-model="editable.machine.chassisStart"
                type="text"
              />
              <input
                v-else
                :value="machine.chassisStart"
                disabled
                type="text"
              />
            </div>
            <div class="w6">
              <strong class="level"
                ><span
                  ><abbr title="Fahrgestellnummer">Fgst.-Nr.</abbr> bis</span
                ><span v-if="dirty('chassisEnd')" :class="$style.changed"
              /></strong>
              <input
                v-if="editable.form"
                v-model="editable.machine.chassisEnd"
                type="text"
              />
              <input v-else :value="machine.chassisEnd" disabled type="text" />
            </div>
          </div>

          <hr />

          <div class="rg-grid">
            <div class="w6">
              <strong class="level"
                ><span>Baujahr von</span
                ><span v-if="dirty('yearStart')" :class="$style.changed"
              /></strong>
              <input
                v-if="editable.form"
                v-model.number="editable.machine.yearStart"
                type="text"
                @keypress="onlyInteger($event)"
              />
              <input v-else :value="machine.yearStart" disabled type="text" />
            </div>
            <div class="w6">
              <strong class="level"
                ><span>Baujahr bis</span
                ><span v-if="dirty('yearEnd')" :class="$style.changed"
              /></strong>
              <input
                v-if="editable.form && !editable.machine.yeartoday"
                v-model.number="editable.machine.yearEnd"
                type="text"
                @keypress="onlyInteger($event)"
              />
              <input
                v-else
                :value="editable.machine.yeartoday ? 'heute' : machine.yearEnd"
                disabled
                type="text"
              />
            </div>
          </div>

          <div class="level">
            <strong class="level"
              ><span
                ><fa
                  class="fa-fw"
                  style="opacity: 0.5"
                  icon="calendar"
                />&nbsp;Wird noch hergestellt</span
              ></strong
            >
            <label class="switch" style="margin-right: 5px">
              <input
                ref="checkyear"
                v-model="editable.machine.yeartoday"
                :disabled="!editable.form"
                type="checkbox"
                @keypress.enter="$refs['checkyear'].click()"
              />
              <span class="slider" />
            </label>
          </div>
          <br />
        </template>
      </rg-widget>
      <br />
      <rg-widget class="w6 s12 l4 form" title="Vererbung">
        <template #data>
          <div class="rg-grid">
            <div class="w6">
              <strong class="level"
                ><span>Parent-ID</span
                ><span v-if="dirty('parentID')" :class="$style.changed"
              /></strong>
              <input
                v-if="editable.form"
                v-model.number="editable.machine.parentID"
                type="number"
                @keypress="onlyInteger($event)"
              />
              <input v-else :value="machine.parentID" disabled type="number" />
            </div>
            <div class="w6">
              <strong class="level"
                ><span>Vererbung nach</span
                ><span v-if="dirty('descentBy')" :class="$style.changed" />
                <span
                  v-if="
                    editable.machine.parentID && !editable.machine.descentBy
                  "
                  style="color: red"
                  >Bitte wählen</span
                >
              </strong>
              <select
                v-if="!editable.form"
                :value="machine.descentBy"
                disabled
                class="rg-select"
              >
                <option value="year">Baujahr</option>
                <option value="chassis">Fahrgestellnummer</option>
                <option value="emission">Abgasnorm</option>
                <option value="engine">Motor</option>
                <option value="transmission">Getriebe</option>
              </select>
              <select
                v-else
                v-model="editable.machine.descentBy"
                class="rg-select"
              >
                <option value="year">Baujahr</option>
                <option value="chassis">Fahrgestellnummer</option>
                <option value="emission">Abgasnorm</option>
                <option value="engine">Motor</option>
                <option value="transmission">Getriebe</option>
              </select>
            </div>
            <div class="level w12">
              <strong class="level"
                ><span
                  ><fa
                    class="fa-fw"
                    style="opacity: 0.5"
                    icon="calendar"
                  />&nbsp;Parent-Fahrzeug</span
                ></strong
              >
              <label class="switch" style="margin-right: 5px">
                <input
                  ref="isParentMachine"
                  v-model="editable.machine.isParent"
                  type="checkbox"
                  :disabled="!editable.form"
                  @change="changeParent(editable.machine.isParent, false)"
                  @keypress.enter="$refs['isParentMachine'].click()"
                />
                <span class="slider" />
              </label>
            </div>
          </div>
        </template>
      </rg-widget>
    </div>
    <div class="w6 s12 l4">
      <rg-widget class="form" title="Redaktion">
        <template #data>
          <strong class="level"
            ><span>Gitlab Ticket</span
            ><span v-if="dirty('gitlabticket')" :class="$style.changed"
          /></strong>
          <input
            v-if="editable.form"
            v-model.number="editable.machine.gitlabticket"
            type="number"
            @keypress="onlyInteger($event)"
          />
          <input v-else :value="machine.gitlabticket" disabled type="number" />

          <strong class="level"
            ><span>Granit-ID</span
            ><span v-if="dirty('granitID')" :class="$style.changed"
          /></strong>
          <input
            v-if="editable.form"
            v-model.number="editable.machine.granitID"
            type="number"
            @keypress="onlyInteger($event)"
          />
          <input v-else :value="machine.granitID" disabled type="number" />

          <strong class="level"
            ><span>Stand der technischen Daten (MM/YYYY)</span
            ><span v-if="dirty('techdataDate')" :class="$style.changed"
          /></strong>
          <input
            v-if="editable.form"
            v-model="editable.machine.techdataDate"
            type="text"
            pattern="\d\d\/\d{4}"
            @keypress="onlyInteger($event, '/')"
          />
          <input v-else :value="machine.techdataDate" disabled type="text" />
        </template>
      </rg-widget>
      <br /><br /><br />
      <rg-widget class="form" title="Daten">
        <template #data>
          <strong class="level"
            ><span>Status (Maschine aktiviert)</span
            ><span v-if="dirty('status')" :class="$style.changed"
          /></strong>
          <select
            v-if="!editable.form"
            :value="machine.status"
            disabled
            class="rg-select"
          >
            <option value="enabled">Aktiviert</option>
            <option value="disabled">Deaktiviert</option>
            <option value="blocked">Blockiert</option>
          </select>
          <select v-else v-model="editable.machine.status" class="rg-select">
            <option value="enabled">Aktiviert</option>
            <option value="disabled">Deaktiviert</option>
            <option value="blocked">Blockiert</option>
          </select>
          <br />
          <div class="level">
            <strong class="level"
              ><span
                ><fa
                  class="fa-fw"
                  style="opacity: 0.5"
                  icon="server"
                />&nbsp;Technische Daten vorhanden</span
              >
              <span v-if="dirty('hasTechdata', true)" :class="$style.changed"
            /></strong>
            <label class="switch" style="margin-right: 5px">
              <input
                ref="checktech"
                v-model="editable.machine.hasTechdata"
                :disabled="!editable.form"
                type="checkbox"
                @keypress.enter="$refs['checktech'].click()"
              />
              <span class="slider" />
            </label>
          </div>
          <br />
          <div class="level">
            <strong class="level"
              ><span
                ><fa
                  class="fa-fw"
                  style="opacity: 0.5"
                  icon="cogs"
                />&nbsp;Komponenten & Teile verknüpft</span
              >
              <span v-if="dirty('hasParts', true)" :class="$style.changed"
            /></strong>
            <label class="switch" style="margin-right: 5px">
              <input
                ref="checkpart"
                v-model="editable.machine.hasParts"
                :disabled="!editable.form"
                type="checkbox"
                @keypress.enter="$refs['checkpart'].click()"
              />
              <span class="slider" />
            </label>
          </div>
          <br />
          <div class="level">
            <strong class="level"
              ><span
                ><fa
                  class="fa-fw"
                  style="opacity: 0.5"
                  icon="clipboard-check"
                />&nbsp;Service Plan hinterlegt</span
              >
              <span
                v-if="dirty('hasServiceplan', true)"
                :class="$style.changed"
            /></strong>
            <label class="switch" style="margin-right: 5px">
              <input
                ref="checkplan"
                v-model="editable.machine.hasServiceplan"
                :disabled="!editable.form"
                type="checkbox"
                @keypress.enter="$refs['checkplan'].click()"
              />
              <span class="slider" />
            </label>
          </div>
          <br />
          <div class="level">
            <strong class="level"
              ><span
                ><fa
                  class="fa-fw"
                  style="opacity: 0.5"
                  icon="user"
                />&nbsp;Zuletzt bearbeitet von</span
              >
            </strong>
            {{ machine.lastEditedByFullName || "Unbekannt" }}
          </div>
          <br />
        </template>
      </rg-widget>
    </div>
    <transition name="opacity">
      <div v-if="duplicate.modalOpen" :class="[$style.modal, 'card']">
        <div class="w12">
          <h1>Maschine duplizieren</h1>
          <br />
        </div>
        <div class="form w12 level" style="align-items: normal">
          <div class="w4">
            <strong>Titel</strong>
            <input v-model="duplicate.title" type="text" />

            <a
              @click="
                duplicate.slug = generateSlug(duplicate.title);
                checkSlug(duplicate.slug, 'duplicate');
              "
              >aus Titel generieren</a
            >
            <div
              v-if="
                duplicate.slug &&
                !isValidSlug.checking &&
                isValidSlug.scope === 'duplicate' &&
                (!isValidSlug.valid || isValidSlug.slugConflict)
              "
              class="is-danger"
              style="margin-bottom: 0px"
            >
              <fa icon="exclamation-triangle" class="fa-fw" />
              <span v-if="isValidSlug.slugConflict"
                >Eine Maschine mit diesem Slug existiert bereits</span
              >
              <span v-else>
                Der Slug ist ungültig (enthält Sonderzeichen/Umlaute oder ein
                "-" am Anfang/Ende oder mehrere Sonderzeichen zwischen den
                Charakteren)
              </span>
            </div>
            <input
              v-model="duplicate.slug"
              type="text"
              :style="
                !isValidSlug.checking &&
                isValidSlug.scope === 'duplicate' &&
                (!isValidSlug.valid || isValidSlug.slugConflict)
                  ? 'border: 1px solid red'
                  : ''
              "
              @input="checkSlug(duplicate.slug, 'duplicate')"
              @change="checkSlug(duplicate.slug, 'duplicate')"
            />
            <strong>Typ</strong>
            <select v-model="duplicate.type" class="rg-select">
              <option
                v-for="type in machineTypes"
                :key="type.id"
                :value="type.key"
              >
                {{ type.titleDe }}
              </option>
            </select>
            <strong>Hersteller</strong>
            <input v-model="duplicate.manufacturer" type="text" />
            <strong>Serie</strong>
            <input v-model="duplicate.series" type="text" />
            <strong>Modell</strong>
            <input v-model="duplicate.model" type="text" />

            <strong>Fahrzeugidentifikationsnummer</strong>
            <input v-model="duplicate.vin" type="text" />

            <strong>GitLab Ticket</strong>
            <input v-model.number="duplicate.gitlabticket" type="number" />

            <strong>Granit ID</strong>
            <input v-model.number="duplicate.granitID" type="number" />
          </div>
          <div class="w4">
            <strong>Motor</strong>
            <input v-model="duplicate.engine" type="text" />

            <strong>Stand der technischen Daten (MM/YYYY)</strong>
            <input
              ref="test"
              v-model="duplicate.techdataDate"
              type="text"
              pattern="\d\d\/\d{4}"
            />
            <strong class="level"
              ><span><abbr title="Fahrgestellnummer">Fgst.-Nr.</abbr> von</span
              ><span v-if="dirty('chassisStart')" :class="$style.changed"
            /></strong>
            <input v-model="duplicate.chassisStart" type="text" />

            <strong class="level"
              ><span><abbr title="Fahrgestellnummer">Fgst.-Nr.</abbr> bis</span
              ><span v-if="dirty('chassisEnd')" :class="$style.changed"
            /></strong>
            <input v-model="duplicate.chassisEnd" type="text" />

            <strong class="level"
              ><span>Baujahr von</span
              ><span v-if="dirty('yearStart')" :class="$style.changed"
            /></strong>
            <input v-model.number="duplicate.yearStart" type="text" />

            <strong class="level"
              ><span>Baujahr bis</span
              ><span v-if="dirty('yearEnd')" :class="$style.changed"
            /></strong>
            <input
              v-if="!duplicate.yeartoday"
              v-model.number="duplicate.yearEnd"
              type="text"
            />
            <input
              v-else
              :value="duplicate.yeartoday ? 'heute' : duplicate.yearend"
              type="text"
              disabled
            />
            <strong class="level"><span>Wird noch hergestellt</span></strong>
            <label class="switch" style="margin-right: 5px">
              <input
                ref="checkyearDuplicate"
                v-model="duplicate.yeartoday"
                type="checkbox"
                @keypress.enter="$refs['checkyearDuplicate'].click()"
              />
              <span class="slider" />
            </label>
            <strong class="level"><span>Industrie</span></strong>
            <select v-model="duplicate.industry" class="rg-select">
              <option
                v-for="industry in machineIndustries"
                :key="industry.id"
                :value="industry.id"
              >
                {{ industry.industry_title }}
              </option>
            </select>
            <strong class="level"><span>Markt</span></strong>
            <select v-model="duplicate.market" class="rg-select">
              <option
                v-for="market in machineMarkets"
                :key="market.id"
                :value="market.id"
              >
                {{ market.market_title }}
              </option>
            </select>
          </div>
          <div class="w4">
            <strong class="level"
              ><span>Parent-ID</span
              ><span v-if="dirty('parentID')" :class="$style.changed"
            /></strong>
            <input
              v-model.number="duplicate.parentID"
              type="number"
              @keypress="onlyInteger($event)"
            />
            <strong class="level"
              ><span>Vererbung nach</span
              ><span
                v-if="duplicate.parentID && !duplicate.descentBy"
                style="color: red"
                >Bitte Verebungshierachie wählen</span
              ></strong
            >
            <select v-model="duplicate.descentBy" class="rg-select">
              <option value="year">Baujahr</option>
              <option value="chassis">Fahrgestellnummer</option>
              <option value="emission">Abgasnorm</option>
              <option value="engine">Motor</option>
              <option value="transmission">Getriebe</option>
            </select>
            <div style="display: flex">
              <strong class="level"><span>Parent-Fahrzeug</span></strong>
            </div>
            <label class="switch" style="margin-right: 5px">
              <input
                ref="isParent"
                v-model="duplicate.isParent"
                type="checkbox"
                @change="changeParent(duplicate.isParent, true)"
                @keypress.enter="$refs['isParent'].click()"
              />
              <span class="slider" />
            </label>
            <div>
              <strong class="level"><span>Modul-Berechtigungen</span></strong>
            </div>
            <div>
              <div
                v-for="extension in extensions"
                :key="extension.id"
                style="margin: 0px"
              >
                <div style="margin: 0px; display: flex">
                  <div style="margin: 0px">
                    <label class="switch" style="margin-right: 5px">
                      <input
                        type="checkbox"
                        :checked="
                          permittedExtensions &&
                          permittedExtensions.includes(extension.id)
                        "
                        @change="changePermission(extension.id)"
                      />
                      <span class="slider" />
                    </label>
                  </div>
                  <div style="margin: 0px">
                    <div style="margin: 0px">
                      <strong>
                        <span>{{ extension.extension_name }}</span>
                      </strong>
                    </div>
                    <div
                      v-if="extension.extension_description"
                      style="margin: 0px"
                    >
                      {{ extension.extension_description }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="w12">
          <div class="flex-right">
            <rg-button
              label="Abbrechen"
              style="margin-right: 5px"
              @click="duplicate.modalOpen = false"
            />
            <rg-button
              :disabled="
                duplicate.disabled ||
                isValidSlug.checking ||
                (isValidSlug.scope === 'duplicate' &&
                  (!isValidSlug.valid || isValidSlug.slugConflict)) ||
                (duplicate.isParent && duplicate.parentID) ||
                (!duplicate.isParent &&
                  (duplicate.parentID
                    ? true
                    : false && duplicate.parentID !== '') &&
                  (!duplicate.descentBy || duplicate.descentBy == ''))
              "
              icon-left="copy"
              label="Duplizieren"
              type="is-primary"
              @click="saveChanges(true)"
            />
          </div>
          <div class="flex-right">
            <span
              v-if="!duplicate.isParent && permittedExtensions.length === 0"
              style="color: red"
              >Es wurden keine Erweiterungen ausgewählt!</span
            >
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref, watch, onMounted, computed } from "@vue/composition-api";
import Vue from "vue";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import { es, onlyInteger, generateSlug } from "@/helper.js";

export default {
  name: "Detail",
  components: {
    rgWidget: () => import("@/components/elements/rg-widget.vue"),
  },
  beforeRouteLeave(to, from, next) {
    next(
      !this.editable.form ||
        window.confirm("Bearbeitung aktiviert. Formular wirklich verlassen?")
    );
  },
  props: { data: { type: Object, default: () => {} } },
  setup(props, context) {
    const getRelevant = Symbol();

    onMounted(() => {
      getMarketsAndIndustries();
      fetchExtensions();
    });

    let machine = ref({
      industry: null,
      market: null,
      type: null,
      defaultMarketTitle: "European Union vendor market",
      parentId: null,
      isParent: false,
    });
    let ac = ref({
      manufacturer: [],
      series: [],
      engine: [],
      transmission: [],
      emission: [],
      type: [],
    });

    let machineMarkets = ref([]);
    let machineIndustries = ref([]);
    let machineTypes = ref([]);

    let editable = ref({
      form: false,
      slug: null,
      machine: {
        parentID: null,
        isParent: false,
      },
    });
    let sources = ref({
      document: { src: "", lang: "de-DE" },
      sharepoint: { src: "", lang: "de-DE" },
      manufacturer: { src: "", lang: "de-DE" },
      [getRelevant]: () => {
        let obj = {};
        if (sources.value.document.src.length) {
          obj.document = { ...sources.value.document };
        }
        if (sources.value.sharepoint.src.length) {
          obj.sharepoint = { ...sources.value.sharepoint };
        }
        if (sources.value.manufacturer.src.length) {
          obj.manufacturer = { ...sources.value.manufacturer };
        }
        return Object.keys(obj).length === 0 && obj.constructor === Object
          ? null
          : obj;
      },
    });
    let languages = ["de-DE", "en-EN", "fr-FR", "es-ES", "it-IT"];
    let isValidSlug = ref({
      valid: false,
      slugConflict: false,
      checking: false,
      scope: null,
    });
    let validationTimeoutId = null;

    let extensions = ref([]);
    let permittedExtensions = ref([]);

    const checkSlugFormat = (slug) => {
      return (
        !RegExp(/^[\W_]+|[\W_]+$/).test(slug) &&
        !RegExp(/\u00c4|\u00e4|\u00d6|\u00f6|\u00dc|\u00fc|\u00df/g).test(
          slug
        ) &&
        !RegExp(/--+|_/g).test(slug) &&
        !RegExp(/[^a-zA-Z0-9-]+/).test(slug)
      );
    };

    getMachineTypes();

    const duplicate = ref({
      modalOpen: false,
      toggleModal: () => {
        duplicate.value.modalOpen = !duplicate.value.modalOpen;
        if (duplicate.value.modalOpen) {
          if (!editable.value.machine) {
            editable.value.machine = { ...machine.value };
            editable.value.machine.yeartoday = false;
          }
          duplicate.value.title = editable.value.machine.title;
          duplicate.value.slug = duplicate.value.title
            .replace(/\s/g, "-")
            .replace(/[(|)|\\.]/g, "")
            .replace(/--+/g, "-")
            .toLowerCase();
          duplicate.value.type = editable.value.machine.type;
          duplicate.value.manufacturer = editable.value.machine.manufacturer;
          duplicate.value.series = editable.value.machine.series;
          duplicate.value.model = editable.value.machine.model;
          duplicate.value.parentID = editable.value.machine.parentID;
          duplicate.value.gitlabticket = editable.value.machine.gitlabticket;
          duplicate.value.granitID = editable.value.machine.granitID;
          duplicate.value.engine = editable.value.machine.engine;
          duplicate.value.techdataDate = editable.value.machine.techdataDate;
          duplicate.value.chassisStart = editable.value.machine.chassisStart;
          duplicate.value.chassisEnd = editable.value.machine.chassisEnd;
          duplicate.value.yearStart = editable.value.machine.yearStart;
          duplicate.value.yearEnd = editable.value.machine.yearEnd;
          duplicate.value.yeartoday = editable.value.machine.yeartoday;
          duplicate.value.market =
            editable.value.machine.machine_markets[0]?.id;
          duplicate.value.industry =
            editable.value.machine.machine_industries[0]?.id;
          duplicate.value.isParent = editable.value.machine.isParent;
          duplicate.value.descentBy = editable.value.machine.descentBy;
          duplicate.value.vin = editable.value.machine.vin;
        }
        checkSlug(duplicate.value.slug, "duplicate");
      },
      title: "",
      slug: "",
      isParent: false,
      parentID: "",
      descentBy: "",
      gitlabticket: "",
      granitID: "",
      engine: "",
      techdataDate: "",
      chassisStart: "",
      chassisEnd: "",
      yearStart: "",
      yearEnd: "",
      disabled: computed(
        () =>
          !duplicate.value.title ||
          !duplicate.value.slug ||
          duplicate.value.slug === editable.value.machine.slug
      ),
    });

    document.addEventListener("keydown", ({ keyCode }) => {
      if (keyCode === 27) duplicate.value.modalOpen = false;
    });

    superagent
      .post(es + "/repguide_machines/_search", {
        query: { match_all: {} },
        size: 0,
        aggs: {
          manufacturer: {
            terms: {
              field: "manufacturer.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          series: {
            terms: {
              field: "series.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          engine: {
            terms: {
              field: "engine.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          transmission: {
            terms: {
              field: "transmission.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          emission: {
            terms: {
              field: "emission.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
          type: {
            terms: {
              field: "type.keyword",
              order: { _count: "desc" },
              size: 999,
            },
          },
        },
      })
      .set(
        "Authorization",
        "ApiKey SHRMYXBYc0I2YjNKWVpPcjlXVG06YXdOcEpTQ0JTYUMyRktKajdOZGxmQQ=="
      )
      .then((res) => {
        for (const key in ac.value) {
          ac.value[key] = res.body?.aggregations?.[key].buckets.map(
            (b) => b.key
          );
        }
      });

    if (props.data) {
      machine.value = { ...props.data };
      editable.value.machine = { ...props.data };
      machine.value.yeartoday = machine.value.year?.includes("heute");
      editable.value.machine.yeartoday = machine.value.year?.includes("heute");
    }

    watch(props, (mecha) => {
      if (mecha.data) {
        machine.value = { ...mecha.data };
        editable.value.machine = { ...mecha.data };
        machine.value.yeartoday = machine.value.year?.includes("heute");
        editable.value.machine.yeartoday =
          machine.value.year?.includes("heute");
      }
    });

    function toggleEdit(val) {
      if (val) {
        editable.value.machine = { ...machine.value };
        editable.value.machine.yeartoday =
          machine.value.year?.includes("heute");
        checkSlug(editable.value.machine.slug, "change");
        checkHierachy();
      }
    }

    function dirty(key, bool) {
      return (
        editable.value.form &&
        (editable.value.machine[key] || (bool ? false : null)) !==
          machine.value[key]
      );
    }

    function changeParent(val, create = false) {
      if (val) {
        if (create) {
          duplicate.value.parentID = null;
          duplicate.value.descentBy = null;
        } else {
          editable.value.machine.parentID = null;
          editable.value.machine.descentBy = null;
        }
      }
    }

    function checkHierachy(create = false) {
      const isParent = create
        ? duplicate.value.isParent
        : editable.value.machine.isParent;
      const parentID = create
        ? duplicate.value.parentID
        : editable.value.machine.parentID;
      const descentBy = create
        ? duplicate.value.descentBy
        : editable.value.machine.descentBy;

      if (isParent && parentID) return false;
      if (!isParent && ((parentID && !descentBy) || (descentBy && !parentID)))
        return false;

      return true;
    }

    async function checkSlug(slug, scope = "change", ms = 500) {
      isValidSlug.value.checking = true;
      isValidSlug.value.valid = false;
      isValidSlug.value.slugConflict = false;
      isValidSlug.value.scope = scope;
      clearTimeout(validationTimeoutId);
      const validationResponse = await new Promise((resolve) => {
        validationTimeoutId = setTimeout(() => {
          if (!checkSlugFormat(slug)) {
            resolve(false);
          } else {
            isValidSlug.value.valid = true;
            const gqQueryMachine = `
            query Machine($slug: String!) {
              Machine(slug: $slug) {
                id
                slug
              }
            }
            `;
            superagent
              .post(altBackendUrl)
              .send({
                query: gqQueryMachine,
                variables: {
                  slug: slug,
                },
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              )
              .then((res) => {
                isValidSlug.value.slugConflict =
                  scope === "change"
                    ? res.body?.data?.Machine?.id &&
                      res.body?.data?.Machine?.id !== machine.value.id
                    : res.body?.data?.Machine?.id;
                if (isValidSlug.value.slugConflict) {
                  resolve(false);
                }
                resolve(true);
              });
          }
        }, ms);
      });
      isValidSlug.value.checking = false;
      return validationResponse;
    }

    function changePermission(id) {
      if (!permittedExtensions.value.includes(id)) {
        permittedExtensions.value.push(id);
      } else {
        permittedExtensions.value.splice(
          permittedExtensions.value.indexOf(id),
          1
        );
      }
    }

    function getMarketsAndIndustries() {
      const gqlQueryMarketsAndIndustries = `
          query MachineMarket {
            MachineMarkets {
              i18n_key
              id
              market_title
            }
            MachineIndustries {
              i18n_key
              id
              industry_title
            }
          }
          `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqlQueryMarketsAndIndustries,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          machineMarkets.value = res.body?.data.MachineMarkets;
          machineIndustries.value = res.body?.data.MachineIndustries;
        });
    }

    /*
     *- get machine_type from machines table
     *- get default_industry_id for above machine_type from machine_types table
     *- get title for above default_industry_id from machine_industries table
     *
     *
     *
     *
     */
    async function getDefaultIndustry() {
      const gqMachineType = `
      query Machine($slug: String!) {
        Machine(slug: $slug) {
          id
          type
        }
      }
      `;
      const type = await superagent
        .post(altBackendUrl)
        .send({
          query: gqMachineType,
          variables: {
            slug: editable.value.machine.slug,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          return res?.body?.data?.Machine?.type;
        });
      const defaultIndustryId = machineTypes.value.find((machineType) => {
        return machineType?.key == type;
      })?.default_industry_id;
      const defaultIndustry = machineIndustries.value.find((industry) => {
        return industry.id == defaultIndustryId;
      }).industry_title;
      editable.value.machine.industry = defaultIndustry;
      return;
    }

    function getMachineTypes() {
      const gqQueryMachineTypes = `
        query MachineTypes {
          MachineTypes {
            default_industry_id
            id
            descriptionDe
            key
            titleDe
          }
        }
        `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryMachineTypes,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          machineTypes.value = res.body?.data?.MachineTypes;
        });
    }

    function fetchExtensions() {
      superagent
        .get(
          "https://repguide-rest.fricke-holding.workers.dev/api/legacy/extensions"
        )
        .then(({ body: extensionList }) => {
          extensions.value = extensionList.data.Extensions;
        })
        .catch((err) => {
          Vue.$toast.open(err);
        });
    }

    function showDevMessage(logPayload = undefined) {
      if (logPayload) console.table(logPayload);
      Vue.$toast.open({
        icon: "check",
        message:
          "Maschine erfolgreich gespeichert. Im Entwicklungsmodus wird die Maschine nicht in die Suche importiert und die Seite nicht automatisch neu geladen.",
        dismissable: true,
        duration: 30_000,
      });
    }

    async function saveChanges(create = false) {
      if (
        !(create
          ? await checkSlug(duplicate.value.slug, "duplicate")
          : await checkSlug(editable.value.machine.slug, "change"))
      ) {
        if (!isValidSlug.value.valid) {
          Vue.$toast.open({
            duration: 60000,
            icon: "exclamation",
            type: "error",
            message:
              "Der Slug enthält ungültige Zeichen. Bitte überprüfen Sie den Slug.",
          });
          return;
        } else if (isValidSlug.value.slugConflict) {
          Vue.$toast.open({
            duration: 60000,
            icon: "exclamation",
            type: "error",
            message:
              "Der Slug existiert bereits. Bitte wählen Sie einen anderen.",
          });
          return;
        }
      }
      const payload = {
        authorid: parseInt(context.root.$store.state.user.id),

        type: editable.value.machine.type?.trim(),
        typeId: machineTypes.value.filter(
          (el) => el.key === editable.value.machine.type
        )[0].id,
        title: editable.value.machine.title?.trim(),
        slug: editable.value.machine.slug?.trim().toLowerCase(),

        manufacturer: editable.value.machine.manufacturer?.trim(),
        series: editable.value.machine.series?.trim() || null,
        model: editable.value.machine.model?.trim() || null,

        vin: editable.value.machine.vin?.trim() || null,
        granitid: parseInt(editable.value.machine.granitID) || null,
        gitlabticket: parseInt(editable.value.machine.gitlabticket) || null,
        origindatetechdata: editable.value.machine.techdataDate?.trim() || null,

        chassisnrstart: editable.value.machine.chassisStart?.trim() || null,
        chassisnrend: editable.value.machine.chassisEnd?.trim() || null,

        engine: editable.value.machine.engine?.trim() || null,
        transmission: editable.value.machine.transmission?.trim() || null,
        emission: editable.value.machine.emission?.trim() || null,

        hastechdata: editable.value.machine.hasTechdata ? true : false,
        hasserviceplan: editable.value.machine.hasServiceplan ? true : false,
        hasparts: editable.value.machine.hasParts ? true : false,
        status: editable.value.machine.status?.trim(),

        language: editable.value.machine.language?.trim() || "de-DE",
        region: editable.value.machine.region?.trim() || "EU",

        yearstart: parseInt(editable.value.machine.yearStart) || null,
        yearend: editable.value.machine.yeartoday
          ? null
          : parseInt(editable.value.machine.yearEnd) || null,

        lastEditedBy: parseInt(context.root.$store.state.user.id) || null,
        market: [editable.value.machine.machine_markets[0].id] || [],
        industry: [editable.value.machine.machine_industries[0].id] || [],
        isParent: editable.value.machine.isParent || false,
        parentid: editable.value.machine.isParent
          ? null
          : parseInt(editable.value.machine.parentID),
        descentby: editable.value.machine.isParent
          ? null
          : editable.value.machine.descentBy?.trim(),
      };

      if (
        payload.yearstart ||
        payload.yearend ||
        editable.value.machine.yeartoday
      ) {
        payload.yearshort =
          (payload.yearstart
            ? payload.yearstart?.toString().slice(-2) + "-"
            : payload.yearend || editable.value.machine.yeartoday
            ? "Bis "
            : "") +
          (editable.value.machine.yeartoday
            ? "heute"
            : payload.yearend?.toString().slice(-2) || "");
        payload.year =
          (payload.yearstart ? payload.yearstart + "-" : "Bis ") +
          (editable.value.machine.yeartoday ? "heute" : payload.yearend || "");
      } else {
        payload.year = null;
        payload.yearshort = null;
      }
      // MARK: - Duplicate Machine
      if (create) {
        if (!checkHierachy(true)) {
          Vue.$toast.open({
            duration: 60000,
            icon: "exclamation",
            type: "error",
            message:
              "Fehler beim Überprüfen der Vererbung. Bitte überprüfen Sie die Eingaben.",
          });
          return;
        }
        payload.title = duplicate.value.title?.trim() || null;
        payload.slug = duplicate.value.slug?.trim() || null;
        payload.type = duplicate.value.type?.trim() || null;
        (payload.typeId = machineTypes.value.filter(
          (el) => el.key === duplicate.value.type
        )[0]?.id),
          (payload.manufacturer = duplicate.value.manufacturer?.trim() || null);
        payload.series = duplicate.value.series?.trim() || null;
        payload.model = duplicate.value.model?.trim() || null;
        payload.parentid = duplicate.value.parentID || null;
        payload.gitlabticket = duplicate.value.gitlabticket || null;
        payload.granitid = duplicate.value.granitID || null;
        payload.engine = duplicate.value.engine?.trim() || null;
        payload.origindatetechdata =
          duplicate.value.techdataDate?.trim() || null;

        payload.hastechdata = false;
        payload.hasserviceplan = false;
        payload.hasparts = false;
        payload.status = "disabled";
        payload.chassisnrstart = duplicate.value.chassisStart || null;
        payload.chassisnrend = duplicate.value.chassisEnd || null;
        payload.yearstart = parseInt(duplicate.value.yearStart) || null; //this could be String if passed wrongly from duplicate object
        payload.yearend = duplicate.value.yeartoday
          ? null
          : parseInt(duplicate.value.yearEnd); //this could be String if passed wrongly from duplicate object
        payload.yearshort =
          (duplicate.value.yearStart
            ? duplicate.value.yearStart?.toString().slice(-2) + "-"
            : duplicate.value.yearEnd || duplicate.value.yeartoday
            ? "Bis "
            : "") +
          (duplicate.value.yeartoday
            ? "heute"
            : duplicate.value.yearEnd?.toString().slice(-2) || "");
        payload.year =
          (duplicate.value.yearStart
            ? duplicate.value.yearStart + "-"
            : duplicate.value.yearEnd || duplicate.value.yeartoday
            ? "Bis "
            : "") +
          (duplicate.value.yeartoday ? "heute" : duplicate.value.yearEnd || "");
        payload.market = [duplicate.value.market] || [];
        payload.industry = [duplicate.value.industry] || [];
        payload.isParent = duplicate.value.isParent || false;
        payload.parentID = duplicate.value.isParent
          ? null
          : duplicate.value.parentID;
        payload.descentby = duplicate.value.isParent
          ? null
          : duplicate.value.descentBy;
        payload.vin = duplicate.value.vin || null;

        const gqCreateMachineMutation = `
        mutation Machine($machineId: Int!, $authorid: Int, $slug: String, $type: Int, $title: String, $manufacturer: String,
        $series: String, $model: String, $parentid: Int, $vin: String, $granitid: Int, $gitlabticket: Int, $origindatetechdata: String,
        $chassisnrstart: String, $chassisnrend: String, $engine: String, $transmission: String, $emission: String, $urlmanufacturer: String,
        $urlwikibooks: String, $urlkonedata: String, $urltraktorbook: String, $comment: String, $hastechdata: Boolean,
        $hasserviceplan: Boolean, $hasparts: Boolean, $status: String, $descentby: String, $language: String, $region: String,
        $yearstart: Int, $yearend: Int, $year: String, $yearshort: String, $machineIndustries: [String!], $machineMarkets: [String!], $isParent: Boolean) {
          Machine(id: $machineId, authorid: $authorid, slug: $slug, type: $type, title: $title, manufacturer: $manufacturer, series: $series,
          model: $model, parentid: $parentid, vin: $vin, granitid: $granitid, gitlabticket: $gitlabticket,
          origindatetechdata: $origindatetechdata, chassisnrstart: $chassisnrstart, chassisnrend: $chassisnrend, engine: $engine,
          transmission: $transmission, emission: $emission, urlmanufacturer: $urlmanufacturer, urlwikibooks: $urlwikibooks,
          urlkonedata: $urlkonedata, urltraktorbook: $urltraktorbook, comment: $comment, hastechdata: $hastechdata,
          hasserviceplan: $hasserviceplan, hasparts: $hasparts, status: $status, descentby: $descentby, language: $language, region: $region,
          yearstart: $yearstart, yearend: $yearend, year: $year, yearshort: $yearshort, machine_industries: $machineIndustries, machine_markets: $machineMarkets, isparent: $isParent) {
            id
          }
        }
      `;

        superagent
          .post(altBackendUrl)
          .send({
            query: gqCreateMachineMutation,
            variables: {
              machineId: -1,
              authorid: payload.authorid,
              slug: payload.slug,
              type: payload.typeId,
              title: payload.title,
              manufacturer: payload.manufacturer,
              series: payload.series,
              model: payload.model,
              parentid: payload.parentid,
              vin: payload.vin,
              granitid: payload.granitid,
              gitlabticket: payload.gitlabticket,
              origindatetechdata: payload.origindatetechdata,
              chassisnrstart: payload.chassisnrstart,
              chassisnrend: payload.chassisnrend,
              engine: payload.engine,
              transmission: payload.transmission,
              emission: payload.emission,
              hastechdata: payload.hastechdata,
              hasserviceplan: payload.hasserviceplan,
              hasparts: payload.hasparts,
              status: payload.status,
              descentby: payload.descentby,
              language: payload.language,
              region: payload.region,
              yearstart: payload.yearstart,
              yearend: payload.yearend,
              year: payload.year,
              yearshort: payload.yearshort,
              lastEditedBy: payload.lastEditedBy,
              machineIndustries: payload.industry,
              machineMarkets: payload.market,
              isParent: payload.isParent,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then((resMachine) => {
            const machineid = resMachine.body.data.Machine.id;
            const sorted_extensions = permittedExtensions.value.sort(
              (a, b) => a - b
            );
            //save permissions
            const mSavePermissionsMutation = `
              mutation Mutation($appPermissionId: Int!, $relationType: String, $relationId: Int, $permittedExtensions: [Int!]) {
                AppPermission(id: $appPermissionId, relation_type: $relationType, relation_id: $relationId, permitted_extensions: $permittedExtensions) {
                  id
                }
              }
              `;
            superagent
              .post(altBackendUrl)
              .send({
                query: mSavePermissionsMutation,
                variables: {
                  appPermissionId: -1,
                  relationType: "machines",
                  relationId: machineid,
                  permittedExtensions: sorted_extensions,
                },
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              )
              .then(() => {
                Vue.$toast.open({
                  message: "Die Berechtigungen wurden erfolgreich gespeichert.",
                  icon: "check",
                });
              })
              .catch((err) => {
                Vue.$toast.open({
                  message: `Fehler beim Speichern der Berechtigungen: ${err}. Bitte die Berechtigungen im Nachgang prüfen.`,
                  icon: "exclamation",
                });
              });

            const hierarchy = {
              join: payload.isParent
                ? { name: "parent" }
                : { name: "child", parent: payload.parentid?.toString() },
              routing: payload.isParent
                ? payload.parentid?.toString()
                : machineid.toString(),
            };

            const contents = [];
            if (payload.hasserviceplan) contents.push("hasserviceplan");
            if (payload.hastechdata) contents.push("hastechdata");
            if (payload.hasparts) contents.push("hasparts");

            const espayload = {
              id: machineid,
              parentid: payload.parentid,
              isparent: payload.isParent,
              hierarchy: hierarchy.join,
              slug: payload.slug,
              status: payload.status,
              title: payload.title,
              manufacturer: payload.manufacturer,
              type: payload.type,
              series: payload.series,
              model: payload.model,
              granitid: payload.granitid,
              gitlabticket: payload.gitlabticket,
              engine: payload.engine,
              transmission: payload.transmission,
              emission: payload.emission,
              hastechdata: payload.hastechdata,
              hasserviceplan: payload.hasserviceplan,
              hasparts: payload.hasparts,
              chassisnrstart: payload.chassisnrstart,
              chassisnrend: payload.chassisnrend,
              yearstart: payload.yearstart,
              yearend: payload.yearend,
              origindatetechdata: payload.origindatetechdata,
              language: payload.language,
              region: payload.region,
              contents,
              machine_industries: [
                machineIndustries.value.find(
                  (industry) => industry.id == payload.industry[0]
                ).i18n_key || "",
              ],
              machine_markets: [
                machineMarkets.value.find(
                  (market) => market.id == payload.market[0]
                ).i18n_key || "",
              ],
            };

            // Handling of ES in dev mode
            if (process.env.NODE_ENV === "development") {
              showDevMessage(espayload);
              return;
            }

            superagent
              .post(
                es +
                  "/repguide_machines/_create/" +
                  machineid +
                  "?routing=" +
                  hierarchy.routing
              )
              .set(
                "Authorization",
                "ApiKey SHRMYXBYc0I2YjNKWVpPcjlXVG06YXdOcEpTQ0JTYUMyRktKajdOZGxmQQ=="
              )
              .send(espayload)
              .then(() => {
                duplicate.value.modalOpen = false;
                Vue.$toast.open({
                  icon: "check",
                  message:
                    "Maschine erfolgreich gespeichert und in die Suche importiert.",
                });
                Vue.$toast.open("Lade neu…");
                setTimeout(() => {
                  context.root.$router.push("/machines/" + machineid + "/type");
                }, 2000);
              })
              .catch((error) => {
                console.trace(error);
                Vue.$toast.open({
                  duration: 60000,
                  icon: "exclamation",
                  type: "error",
                  message:
                    "Die Maschie wurde erfolgreich gespeichert, konnte jedoch nicht in die Suche importiert werden.",
                });
                Vue.$toast.open({
                  duration: 60000,
                  icon: "info",
                  message:
                    "Um den Import in die Suche zu wiederholen, öffne die Maschine und speichere die Typenstruktur erneut.",
                });
                Vue.$toast.open({
                  duration: 60000,
                  icon: "tractor",
                  message:
                    "Hier klicken um die Maschine #" +
                    machineid +
                    " jetzt öffnen",
                  onClick: () => {
                    context.root.$router.push(
                      "/machines/" + machineid + "/type"
                    );
                  },
                });
              });
          })
          .catch((error) => {
            console.trace(error);
            Vue.$toast.open({
              duration: 10000,
              icon: "exclamation",
              type: "error",
              message:
                "Beim Speichern der Maschine ist ein Fehler aufgetreten. Bitte überprüfe deine Eingabe und versuche es erneut.",
            });
          });
        // MARK: - Update Machine
      } else {
        if (!checkHierachy()) {
          Vue.$toast.open({
            duration: 60000,
            icon: "exclamation",
            type: "error",
            message:
              "Fehler beim Überprüfen der Vererbung. Bitte überprüfen Sie die Eingaben.",
          });
          return;
        }
        const gqSaveMachineMutation = `
        mutation Machine($machineId: Int!, $slug: String, $type: Int, $title: String, $manufacturer: String,
        $series: String, $model: String, $parentid: Int, $vin: String, $granitid: Int, $gitlabticket: Int, $origindatetechdata: String,
        $chassisnrstart: String, $chassisnrend: String, $engine: String, $transmission: String, $emission: String, $hastechdata: Boolean,
        $hasserviceplan: Boolean, $hasparts: Boolean, $status: String, $descentby: String, $language: String, $region: String,
        $yearstart: Int, $yearend: Int, $year: String, $yearshort: String, $lastEditedBy: Int, $machineMarkets: [String!]!, $machineIndustries: [String!]!, $isParent: Boolean) {
          Machine(id: $machineId, slug: $slug, type: $type, title: $title, manufacturer: $manufacturer, series: $series,
          model: $model, parentid: $parentid, vin: $vin, granitid: $granitid, gitlabticket: $gitlabticket,
          origindatetechdata: $origindatetechdata, chassisnrstart: $chassisnrstart, chassisnrend: $chassisnrend, engine: $engine,
          transmission: $transmission, emission: $emission, hastechdata: $hastechdata,
          hasserviceplan: $hasserviceplan, hasparts: $hasparts, status: $status, descentby: $descentby, language: $language, region: $region,
          yearstart: $yearstart, yearend: $yearend, year: $year, yearshort: $yearshort, last_edited_by: $lastEditedBy, machine_industries: $machineIndustries, machine_markets: $machineMarkets, isparent: $isParent) {
            id
          }
        }
        `;

        const gqMachineAttributesView = `
            query qMachineAttributes($id: Int!) {
            Machine(id: $id) {
              Attributes {
              value
        }
      }
    }
            `;
        superagent
          .post(altBackendUrl)
          .send({
            query: gqSaveMachineMutation,
            variables: {
              machineId: parseInt(machine.value.id),
              slug: payload.slug,
              type: payload.typeId,
              title: payload.title,
              manufacturer: payload.manufacturer,
              series: payload.series,
              model: payload.model,
              vin: payload.vin,
              granitid: payload.granitid,
              gitlabticket: payload.gitlabticket,
              origindatetechdata: payload.origindatetechdata,
              chassisnrstart: payload.chassisnrstart,
              chassisnrend: payload.chassisnrend,
              engine: payload.engine,
              transmission: payload.transmission,
              emission: payload.emission,
              hastechdata: payload.hastechdata,
              hasserviceplan: payload.hasserviceplan,
              hasparts: payload.hasparts,
              status: payload.status,
              language: payload.language,
              region: payload.region,
              yearstart: payload.yearstart,
              yearend: payload.yearend,
              year: payload.year,
              yearshort: payload.yearshort,
              lastEditedBy: payload.lastEditedBy,
              machineMarkets: payload.market,
              machineIndustries: payload.industry,
              isParent: payload.isParent,
              parentid: payload.parentid,
              descentby: payload.descentby,
            },
          })
          .set(
            "Authorization",
            `Bearer ${context.root.$store.state.user.apiToken}`
          )
          .then(async (resMachine) => {
            const machineId = resMachine.body.data.Machine.id;

            const hierarchy = {
              join: payload.isParent
                ? { name: "parent" }
                : { name: "child", parent: payload.parentid?.toString() },
              routing: payload.isParent
                ? machine.value.id.toString()
                : payload.parentId?.toString(),
            };

            const { body } = await superagent

              .post(altBackendUrl)

              .send({
                query: gqMachineAttributesView,

                variables: {
                  id: machineId,
                },
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              );

            const contents = [];
            if (payload.hasServiceplan) contents.push("hasServiceplan");
            if (payload.hasTechdata) contents.push("hasTechdata");
            if (payload.hasParts) contents.push("hasParts");

            const espayload = {
              id: machine.value.id,
              parentid: payload.parentid,
              isparent: payload.isParent,
              hierarchy: hierarchy.join,
              slug: editable.value.machine.slug,
              status: payload.status,
              title: payload.title,
              manufacturer: payload.manufacturer,
              type: payload.type,
              series: payload.series,
              model: payload.model,
              granitid: payload.granitid,
              gitlabticket: payload.gitlabticket,
              engine: payload.engine,
              transmission: payload.transmission,
              emission: payload.emission,
              hastechdata: payload.hastechdata,
              hasserviceplan: payload.hasserviceplan,
              hasparts: payload.hasparts,
              chassisnrstart: payload.chassisnrstart,
              chassisnrend: payload.chassisnrend,
              yearstart: payload.yearstart,
              yearend: payload.yearend,
              origindatetechdata: payload.origindatetechdata,
              language: payload.language,
              region: payload.region,
              contents,
              attributes: body.data.Machine.Attributes,
              machine_industries: [
                machineIndustries.value.find(
                  (industry) => industry.id == payload.industry
                ).i18n_key || "",
              ],
              machine_markets: [
                machineMarkets.value.find(
                  (market) => market.id == payload.market
                ).i18n_key || "",
              ],
            };

            // Handling of ES in dev mode
            if (process.env.NODE_ENV === "development") {
              showDevMessage(espayload);
              return;
            }

            superagent
              .post(es + "/repguide_machines/_update/" + machine.value.id)
              .set(
                "Authorization",
                "ApiKey SHRMYXBYc0I2YjNKWVpPcjlXVG06YXdOcEpTQ0JTYUMyRktKajdOZGxmQQ=="
              )
              .send({ doc: espayload })
              .then(() => {
                Vue.$toast.open({
                  icon: "check",
                  message:
                    "Maschine erfolgreich gespeichert und in die Suche importiert.",
                });
                Vue.$toast.open("Lade neu…");
                setTimeout(() => {
                  context.root.$router.go();
                }, 2000);
                duplicate.value.modalOpen = false;
              })
              .catch((error) => {
                console.trace(error);
                Vue.$toast.open({
                  duration: 60000,
                  icon: "exclamation",
                  type: "error",
                  message:
                    "Die Maschie wurde erfolgreich gespeichert, konnte jedoch nicht in die Suche importiert werden.",
                });
                Vue.$toast.open({
                  duration: 60000,
                  icon: "info",
                  message:
                    "Um den Import zu wiederholen, speichere die Typenstruktur erneut ab.",
                });
              });
          })
          .catch((error) => {
            console.trace(error);
            Vue.$toast.open({
              duration: 10000,
              icon: "exclamation",
              type: "error",
              message:
                "Beim Speichern der Maschine ist ein Fehler aufgetreten. Bitte überprüfe deine Eingabe und versuche es erneut.",
            });
          });
      }
    }

    return {
      machine,
      editable,
      languages,
      sources,
      toggleEdit,
      dirty,
      onlyInteger,
      saveChanges,
      ac,
      duplicate,
      machineTypes,
      checkSlug,
      generateSlug,
      machineMarkets,
      machineIndustries,
      getDefaultIndustry,
      isValidSlug,
      checkHierachy,
      extensions,
      changePermission,
      permittedExtensions,
      changeParent,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}

.modal {
  position: fixed;
  top: calc(50% + 35px);
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999999999;
  width: 1400px;
  border-radius: 16px;
  padding: 40px 30px 0;
  max-height: 800px;
  overflow-y: auto;
  max-height: 80vh;
  div:last-of-type {
    margin-bottom: 40px;
  }
}
</style>
