<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/accounts/add">
            <rg-button
              type="is-primary"
              icon-left="user-plus"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template #trigger>
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/accounts/add">
              <rg-button icon-left="user-plus" label="Account" />
            </router-link>
            <router-link to="/companies/add">
              <rg-button icon-left="users" label="Unternehmen" />
            </router-link>
            <router-link to="/associations/add">
              <rg-button icon-left="building" label="Unternehmensgruppe" />
              <router-link to="/contracts/add">
                <rg-button icon-left="file-contract" label="Vertrag" />
              </router-link>
            </router-link>
          </rg-dropdown>
        </div>
      </div>
    </navigation>
    <div class="rg-grid" style="padding: 15px">
      <div class="level w12">
        <h1>Account anlegen</h1>
        <div>
          <rg-button
            type="is-primary"
            icon-left="save"
            label="Speichern"
            :disabled="!checkFormInput()"
            @click="addUser()"
          />
        </div>
      </div>
      <rg-widget title="Allgemein" class="w8 form">
        <template #data>
          <strong class="level"><span>Anrede</span> </strong>
          <select v-model="data.salutation" class="rg-select">
            <optgroup
              v-for="(group, name) in salutations"
              :key="name"
              :label="name"
            >
              <option
                v-for="option in group"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </optgroup>
          </select>
          <div v-if="!['Herr', 'Frau'].includes(data.salutation)">
            <strong><span>Anrede Freitext</span></strong>
            <input v-model="data.salutation" type="text" />
          </div>
          <strong class="level"><span>Geschlecht</span> </strong>
          <select v-model="data.sex" class="rg-select">
            <optgroup v-for="(group, name) in sexes" :key="name" :label="name">
              <option
                v-for="option in group"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </option>
            </optgroup>
          </select>

          <strong class="level"><span>Vorname</span> </strong>
          <input v-model="data.prename" type="text" />

          <strong class="level"><span>Nachname</span> </strong>
          <input v-model="data.name" type="text" />

          <strong class="level"><span>E-Mail</span> </strong>
          <span
            v-if="data.emailValidated !== null && !data.emailValidated"
            style="color: red"
            >Ungültige E-Mail Adresse</span
          >
          <input v-model="data.email" type="text" />

          <strong class="level"><span>Login (Benutzername)</span> </strong>
          <span
            v-if="data.loginValidated !== null && !data.loginValidated"
            style="color: red"
            >Benutzername existiert bereits</span
          >
          <input
            v-model="data.login"
            type="text"
            @blur="validateLogin(data.login)"
          />

          <hr />

          <strong class="level"><span>Kommentar</span> </strong>
          <input v-model="data.comment" type="text" />
          <strong class="level"><span>Nutzer Rollen</span> </strong>
          <select v-model="data.role" class="rg-select" multiple>
            >
            <option
              v-for="option in roles"
              :key="option"
              :value="option"
              @click="changeRoles(option)"
            >
              {{ option }}
            </option>
          </select>

          <strong class="level"><span>Benutzergruppe</span> </strong>
          <select v-model="data.tier" class="rg-select">
            >
            <option
              v-for="option in pricingTiers"
              :key="option"
              :value="option"
            >
              {{ option }}
            </option>
          </select>
        </template>
      </rg-widget>
      <rg-widget title="Informationen" class="w4 form">
        <template #data>
          <div v-if="autofill" class="level">
            <div>
              <fa icon="exclamation-triangle" class="fa-fw" />
              <span>Einige Informationen wurden automatisch ausgefüllt</span>
            </div>
            <rg-button
              type="is-grey"
              icon-left="trash"
              title="Entfernen"
              label="Entfernen"
              @click="removeAutofill()"
            />
          </div>
          <div
            :style="
              data.company &&
              data.company.title &&
              !data.company.managing_user_id
                ? 'border: 2px solid red; padding: 10px;'
                : ''
            "
          >
            <strong class="level"><span>GRANIT Kundennr.</span></strong>
            <input
              v-model="data.granitid"
              class="level"
              type="text"
              @blur="checkUserCompany()"
              @keypress.enter="checkUserCompany()"
            />
            <strong class="level"><span>Firma des Benutzers</span></strong>
            <input
              v-model="data.company.title"
              class="level"
              type="text"
              :disabled="!data.granitid"
            />
            <div
              v-if="
                data.company &&
                data.company.title &&
                !data.company.managing_user_id
              "
              style="color: red"
            >
              <h4>
                <span style="font-size: 22px; margin-right: 5px; color: orange"
                  >&#9888;</span
                >
                Diesem Unternehmen wurde kein Manager zugewiesen. Soll der neue
                Benutzer als Manager hinterlegt werden?
              </h4>
              <div class="level">
                <strong class="level" style="color: black"
                  ><span>Als Manager hinzufügen</span>
                </strong>
                <label class="switch">
                  <input
                    v-model="createManager"
                    type="checkbox"
                    :checked="createManager"
                  />
                  <span class="slider" />
                </label>
              </div>
            </div>
          </div>
          <hr />
          <h3>Regionale Einstellungen</h3>
          <strong class="level"><span>Land</span> </strong>
          <select v-model="data.country" class="rg-select">
            >
            <option v-for="option in countries" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
          <strong class="level"><span>Kommunikationssprache</span> </strong>
          <select v-model="data.language" class="rg-select">
            >
            <option
              v-for="option in languages"
              :key="option.id"
              :value="option"
            >
              {{ option.language_name }} ({{ option.language_code }})
            </option>
          </select>
          <hr />
          <h3>Status</h3>
          <div class="level">
            <strong class="level"><span>Account aktiviert</span> </strong>
            <label class="switch">
              <input v-model="data.status" type="checkbox" disabled />
              <span class="slider" />
            </label>
          </div>
          <hr />
          <h3>Optionen</h3>
          <div class="level">
            <strong class="level"
              ><span>Zugangsdaten per E-Mail senden</span>
            </strong>
            <label class="switch">
              <input
                v-model="data.sendCredentials"
                type="checkbox"
                :checked="data.sendCredentials"
              />
              <span class="slider" />
            </label>
          </div>
          <br />
          <div class="level">
            <strong class="level"
              ><span>Erstelltes Passwort anzeigen</span>
            </strong>
            <label class="switch">
              <input
                v-model="data.showPassword"
                type="checkbox"
                :checked="data.showPassword"
                :disabled="!data.sendCredentials"
              />
              <span class="slider" />
            </label>
          </div>
        </template>
      </rg-widget>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect, onMounted } from "@vue/composition-api";
import { formDirty } from "@/helper.js";
import dropdown from "@/components/elements/rg-dropdown";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import Vue from "vue";
import { customAlphabet } from "nanoid";

export default {
  components: {
    // dropdown,
    "rg-dropdown": dropdown,
    "rg-widget": () => import("@/components/elements/rg-widget"),
  },
  beforeRouteLeave(to, from, next) {
    next(
      !this.data.name ||
        window.confirm("Bearbeitung aktiviert. Formular wirklich verlassen?")
    );
  },
  setup(props, context) {
    let data = ref({
      loginValidated: null,
      emailValidated: null,
      email: "",
      status: false,
      sendCredentials: true,
      showPassword: false,
      country: "",
      role: [],
      language: {},
      granitid: "",
      company: { title: null },
    });
    let createManager = ref(false);
    let user = ref({});
    let salutations = {
      Herr: [{ text: "Herr", value: "Herr" }],
      Frau: [{ text: "Frau", value: "Frau" }],
      Andere: [{ text: "Freitext", value: "" }],
    };
    //TODO change to dynamic data
    let sexes = {
      male: [{ text: "männlich", value: "m" }],
      female: [{ text: "weiblich", value: "w" }],
      other: [{ text: "andere", value: "u" }],
    };
    let countries = ref([]);
    let languages = ref([]);
    let autofill = ref(false);
    let pricingTiers = ref([]);
    let roles = ref([]);
    let selectedRoles = ref([]);

    onMounted(() => {
      getPricingTiers();
      getCountries();
      getLanguages();
      getRoles();
      data.value.granitid = context.root.$route.params.granitid || "";
      data.value.company = context.root.$route.params.company || "";
      data.value.country = context.root.$route.params.country || "";
      if (data.value.granitid || data.value.company || data.value.country)
        autofill.value = true;
      if (data.value.granitid) checkUserCompany();
    });

    function changeRoles(role) {
      if (selectedRoles.value.includes(role)) {
        selectedRoles.value = selectedRoles.value.filter(
          (element) => element !== role
        );
      } else {
        selectedRoles.value.push(role);
      }
      data.value.role = [...selectedRoles.value];
    }

    function getRoles() {
      const gqQueryRoles = `
      query MyQuery {
        UserRoles {
          role_name
        }
      }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryRoles,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          roles.value = res.body.data.UserRoles.map((o) => o.role_name);
        });
    }

    function checkUserCompany() {
      superagent
        .get(
          `https://repguide-rest.fricke-holding.workers.dev/api/legacy/company/by-granit/${data.value.granitid}`
        )
        .then((res) => {
          data.value.company = res.body?.data?.Company || null;
          if (data.value.company.managing_user_id) createManager.value = false;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    let companies = ref({});
    const alphabet = "123456789abcdefghkmnprstuvwxyzABCDEFGHKMNPQRSTUVWXYZ";
    const nanoid = customAlphabet(alphabet, 7);

    watchEffect(() => {
      const checkRegEx = /[a-zA-Z0-9_-]*.[@][a-zA-Z0-9_-]*[.][a-z]{2,3}/;
      if (user.value.email === "") {
        user.value.emailValidated = null;
      } else if (checkRegEx.test(user.value.email)) {
        user.value.emailValidated = true;
      } else {
        user.value.emailValidated = false;
      }
      user.value = data.value;
      if (!data.value.sendCredentials) {
        data.value.showPassword = true;
      }
    });

    function checkFormInput() {
      if (
        user.value.role &&
        user.value.salutation &&
        user.value.sex &&
        user.value.prename &&
        user.value.name &&
        user.value.email &&
        user.value.emailValidated &&
        user.value.login &&
        user.value.loginValidated &&
        user.value.country &&
        data.value.granitid &&
        user.value.tier &&
        user.value.language.id &&
        user.value.language.language_code &&
        data.value.company?.title
      )
        return true;
    }

    function getPricingTiers() {
      const gqQueryTiers = `query Query($limit: Int!, $skip: Int!){
              Pricings(limit: $limit, skip: $skip){
                tier
              }
            }`;

      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryTiers,
          variables: { isactive: true, limit: 100, skip: 0 },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          pricingTiers.value = new Set(
            res.body.data.Pricings.filter((p) => p.tier)
              .map((p) => p.tier)
              .sort()
          );
        });
    }

    function validateLogin(login) {
      superagent
        .get(
          `https://repguide-rest.fricke-holding.workers.dev/api/legacy/user/by-login/${login}`
        )
        .then((res) => {
          user.value.loginValidated = res.body?.data?.User?.id ? false : true;
        });
    }

    function getCountries() {
      const gqQueryAppCountries = `query Query {
        AppCountries {
          country_code
        }
      }`;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryAppCountries,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resCountries) => {
          countries.value = Array.from(
            resCountries.body?.data?.AppCountries,
            (el) => el.country_code
          );
        });
    }

    function getLanguages() {
      const gqQueryAppLanguages = `query Query {
        AppLanguages {
          id
          language_code
          language_name
        }
      }`;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryAppLanguages,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resLanguages) => {
          languages.value = resLanguages.body?.data?.AppLanguages;
        });
    }

    function removeAutofill() {
      const autofillProperties = ["granitid", "company", "country"];
      autofillProperties.forEach((el) => {
        data.value[el] = null;
      });
      autofill.value = false;
    }

    function addUser() {
      const payload = {
        salutation: data.value.salutation || null,
        sex: data.value.sex || "u",
        // status: data.value.status ? true : false,
        prename: data.value.prename?.trim() || null,
        name: data.value.name?.trim() || null,
        email: data.value.email?.trim() || null,
        userLogin: data.value.login?.trim() || null,
        granitid: data.value.granitid?.trim() || null,
        company: data.value.company?.title?.trim() || null,
        comment: data.value.comment || null,
        tier: data.value.tier || "customer-normal",
        country: data.value.country || "DE",
        user_roles: data.value.role,
        password: nanoid(),
        languageId: data.value.language.id || 2,
        languageCode: data.value.language.language_code || "en",
      };

      const createUserMutation = `mutation Mutation($userId: Int!, $userLogin: String, $userEmail: String,
        $userSalutation: String, $userPrename: String, $userName: String, $userSex: String,
        $userGranitid: String, $userCompany: String, $userTier: String, $userComment: String,
        $userCountry: String, $userRoles: [String!]!, $languageForContactId: Int) {
          User(
            userId: $userId,
            login: $userLogin,
            email: $userEmail,
            salutation: $userSalutation,
            prename: $userPrename,
            name: $userName,
            sex: $userSex,
            granitid: $userGranitid,
            company: $userCompany,
            tier: $userTier,
            comment: $userComment,
            country: $userCountry,
            user_roles: $userRoles,
            language_for_contact_id: $languageForContactId) {
              email
              id
            }
          }
      `;
      const setUserToManager = `
      mutation createManager($companyId: Int, $managerId: Int){
        Company(id: $companyId, managing_user_id: $managerId){
          Manager{
            id
          }
        }
      }`;

      const setPasswordMutation = `
        mutation PasswordMutation($userId: Int!, $userPassword: String!, $informUser: Boolean) {
          Password(userId: $userId, password: $userPassword, needsReset: true, informUser: $informUser) {
            id,
            login: userName
          }
        }
      `;
      superagent
        .post(altBackendUrl)
        .send({
          query: createUserMutation,
          variables: {
            userId: -1,
            userLogin: payload.login,
            userEmail: payload.email,
            userSalutation: payload.salutation,
            userPrename: payload.prename,
            userName: payload.name,
            userSex: payload.sex,
            userGranitid: payload.granitid,
            userCompany: payload.company,
            userTier: payload.tier,
            userComment: payload.comment,
            userCountry: payload.country,
            userRoles: payload.user_roles,
            languageForContactId: payload.languageId,
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((resUser) => {
          if (!resUser.body?.data?.errors && resUser.body?.data?.User) {
            Vue.$toast.open({
              icon: "check",
              message: "Der Benutzer wurde erfolgreich gespeichert.",
            });
          } else {
            Vue.$toast.open({
              icon: "times-circle",
              message: "FEHLER: Der Benutzer konnte nicht gespeichert werden.",
              type: "error",
            });
            console.table(payload);
            console.log(resUser.body?.data?.errors);
          }
          if (createManager.value === true && resUser?.body?.data?.User?.id) {
            superagent
              .post(altBackendUrl)
              .send({
                query: setUserToManager,
                variables: {
                  managerId: resUser.body.data.User.id,
                  companyId: data.value.company.id,
                },
              })
              .set(
                "Authorization",
                `Bearer ${context.root.$store.state.user.apiToken}`
              )
              .then((resManager) => {
                if (resManager?.body?.data?.Company?.Manager?.id) {
                  Vue.$toast.open({
                    icon: "check",
                    message:
                      "Der User wurde Erfolgreich als Manager hinzugefügt.",
                  });
                } else {
                  Vue.$toast.open({
                    icon: "times-circle",
                    type: "error",
                    message:
                      "FEHLER: Der User konnte nicht als Manager hinzugefügt werden",
                  });
                }
              })
              .catch(() => {
                Vue.$toast.open({
                  icon: "times-circle",
                  type: "error",
                  message:
                    "FEHLER: Der User konnte nicht als Manager hinzugefügt werden",
                });
              });
          } else if (!resUser?.body?.data?.User?.id) {
            Vue.$toast.open({
              icon: "times-circle",
              type: "error",
              message:
                "FEHLER: Der User konnte nicht als Manager hinzugefügt werden, da er eventuell nicht korrekt gespeichert wurde. ",
            });
          }
          superagent
            .post(altBackendUrl)
            .send({
              query: setPasswordMutation,
              variables: {
                userId: resUser.body.data.User.id,
                userPassword: payload.password,
                informUser: false,
              },
            })
            .set(
              "Authorization",
              `Bearer ${context.root.$store.state.user.apiToken}`
            )
            .then((resPW) => {
              if (!resPW.body?.data?.errors && resPW.body?.data?.Password) {
                Vue.$toast.open({
                  icon: "check",
                  message:
                    "Das initiale Passwort wurde erfolgreich gespeichert.",
                });
              } else {
                Vue.$toast.open({
                  icon: "times-circle",
                  type: "error",
                  message:
                    "FEHLER: Das Passwort konnte nicht gespeichert werden.",
                });
                console.table(payload);
                console.log(resPW.body?.data?.errors);
              }
              if (data.value.sendCredentials) {
                superagent
                  .post(
                    "https://repguide-rest.fricke-holding.workers.dev/api/legacy/send_registermail"
                  )
                  .send({
                    email: payload.email,
                    genericPW: payload.password,
                    name: payload.name,
                    prename: payload.prename,
                    username: resPW.body.data.Password.login,
                    language: payload.languageCode,
                    country: payload.country,
                  })
                  .then((resMail) => {
                    if (
                      !resMail.body?.data?.errors &&
                      resMail.body?.data?.RegisterMail
                    ) {
                      Vue.$toast.open({
                        icon: "check",
                        message: "Die E-Mail wurde erfolgreich versendet.",
                      });
                    } else {
                      Vue.$toast.open({
                        icon: "times-circle",
                        type: "error",
                        message:
                          "FEHLER: Die E-Mail konnte nicht versendet werden.",
                      });
                      console.table(payload);
                      console.log(resMail.body?.data?.errors);
                    }
                  });
              }
              if (data.value.showPassword) {
                Vue.$toast.open({
                  icon: "key",
                  message: `Das initiale Passwort lautet: ${payload.password}
                      (klicken zum kopieren)`,
                  duration: 9e9,
                  onClick: () => {
                    navigator.clipboard
                      .writeText(payload.password)
                      .then(() => {
                        Vue.$toast.open({
                          icon: "check",
                          message: `Passwort wurde in die Zwischenablage kopiert!`,
                          duration: 3000,
                        });
                        setTimeout(() => {
                          context.root.$router.go();
                        }, 2000);
                      })
                      .catch(() => {
                        Vue.$toast.open({
                          icon: "times",
                          message: `Fehler beim Kopieren des Passworts!`,
                          duration: 10000,
                        });
                      });
                  },
                });
              } else {
                setTimeout(() => {
                  context.root.$router.go();
                }, 2000);
              }
            })
            .catch((err) => {
              Vue.$toast.open({
                icon: "times-circle",
                type: "error",
                message:
                  "FEHLER: Das Passwort konnte nicht gespeichert werden.",
              });
              console.table(payload);
              console.error(err?.response?.body?.errors);
            });
        })
        .catch((err) => {
          Vue.$toast.open({
            icon: "times-circle",
            message: "FEHLER: Der Benutzer konnte nicht gespeichert werden.",
            type: "error",
          });
          console.table(payload);
          console.error(err?.response?.body?.errors);
        });
      // console.table(payload);
      // console.log(props);
    }

    return {
      data,
      formDirty,
      salutations,
      addUser,
      pricingTiers,
      sexes,
      countries,
      roles,
      checkFormInput,
      validateLogin,
      companies,
      languages,
      autofill,
      removeAutofill,
      checkUserCompany,
      createManager,
      changeRoles,
    };
  },
};
</script>

<style lang="scss" module>
.detail {
  grid-auto-flow: dense;
}

.changed {
  height: 10px;
  width: 10px;
  background: red;
  display: inline-block;
  border-radius: 5px;
  margin-left: 5px;
}
</style>
