<template>
  <div>
    <navigation>
      <div style="display: flex; gap: 5px">
        <div>
          <router-link to="/companies/add">
            <rg-button
              type="is-primary"
              icon-left="industry"
              title="Anlegen"
              label="Anlegen"
              style="border-top-right-radius: 0; border-bottom-right-radius: 0"
            />
          </router-link>
          <rg-dropdown>
            <template #trigger>
              <rg-button
                icon="chevron-down"
                style="border-top-left-radius: 0; border-bottom-left-radius: 0"
              />
            </template>
            <router-link to="/accounts/add">
              <rg-button icon-left="user-plus" label="Account" />
            </router-link>
            <router-link to="/companies/add">
              <rg-button icon-left="industry" label="Unternehmen" />
              <router-link to="/associations/add">
                <rg-button icon-left="building" label="Unternehmensgruppe" />
              </router-link>
              <router-link to="/contracts/add">
                <rg-button icon-left="file-contract" label="Vertrag" />
              </router-link>
            </router-link>
          </rg-dropdown>
        </div>
      </div>
    </navigation>
    <main>
      <h1>Nutzerverwaltung</h1>
      <br />
      <rg-tabs>
        <router-link tag="button" replace to="/accounts/">
          <fa icon="users" />&nbsp;Accounts
        </router-link>

        <router-link tag="button" replace to="/companies/">
          <fa icon="industry" />&nbsp;Unternehmen
        </router-link>
        <router-link tag="button" replace to="/associations/">
          <fa icon="building" />&nbsp;Unternehmensgruppen
        </router-link>
      </rg-tabs>
      <br />
      <input
        v-model="resultFilter.search"
        type="text"
        placeholder="Unternehmensname, Granit-ID"
        @input="dispatchSearch()"
        @keypress.enter="dispatchSearch(0)"
      />
      <br />
      <div v-if="!resultFilter.loading">
        <h2>{{ companiesCount }} Ergebnisse</h2>
        <rg-button
          :disabled="page.current <= 1"
          icon="angle-double-left"
          @click="stride(page.current, 1)"
        />
        <rg-button
          :disabled="page.current <= 1"
          icon="angle-left"
          @click="stride(page.current, page.current - 1)"
        />
        {{ page.current }} von {{ Math.ceil(pages) }}
        <rg-button
          :disabled="page.current >= Math.ceil(pages)"
          icon="angle-right"
          @click="stride(page.current, page.current + 1)"
        />
        <rg-button
          :disabled="page.current >= Math.ceil(pages)"
          icon="angle-double-right"
          @click="stride(page.current, Math.ceil(pages))"
        />
      </div>
      <rg-table
        :rows="data"
        cols="id, granit_id, title, managing_user_id, sales_manager_id, sap_id"
      >
        <template #default="slotProps">
          <div v-show="resultFilter.loading">LOADING</div>
          <template v-for="row in slotProps.chunk">
            <tr :key="row.id">
              <td v-for="(value, property) in row" :key="property">
                <code
                  v-if="['id', 'granit_id'].includes(property)"
                  v-clipboard="value"
                  v-clipboard:success="clipboardSuccessHandler"
                >
                  {{ value }}
                </code>
                <span v-else>{{ value }}</span>
              </td>
              <td>
                <div class="flex-right" style="gap: 5px">
                  <router-link :to="'/companies/' + row.id">
                    <rg-button
                      label="Öffnen"
                      type="is-primary"
                      title="Diesen Eintrag bearbeiten"
                    />
                  </router-link>
                  <router-link :to="'/companies/' + row.id + '/accounts'">
                    <rg-button
                      label="Nutzer"
                      title="Alle Accounts dieses Unternehmens anzeigen"
                    />
                  </router-link>
                  <router-link :to="'/companies/' + row.id + '/contracts'">
                    <rg-button
                      label="Verträge"
                      title="Alle Verträge dieses Unternehmens anzeigen"
                    />
                  </router-link>
                  <dropdown :stay="true" :width="225">
                    <template #trigger>
                      <rg-button icon="chevron-down" />
                    </template>
                    <p><strong>Aktionen</strong></p>
                    <rg-button
                      type="is-primary"
                      icon-left="headset"
                      label="Ticket erstellen"
                      @click="createTicket(row)"
                    />
                    <p>Gebietsleiter eintragen</p>
                    <select
                      class="rg-select"
                      :style="{ width: '215px', margin: 'auto' }"
                      @change="updateSalesRepresentative(row, $event)"
                    >
                      <option :key="null" :value="null" />
                      <option
                        v-for="option in salesUsers"
                        :key="option.id"
                        :value="option.id"
                        :selected="option.id === row.sales_representative_id"
                      >
                        {{ option.prename }} {{ option.name }} ({{ option.id }})
                      </option>
                    </select>
                  </dropdown>
                </div>
              </td>
            </tr>
          </template>
        </template>
      </rg-table>
    </main>
  </div>
</template>

<script>
import rg_tabs from "@/components/elements/rg-tabs";
import { ref, onBeforeMount } from "@vue/composition-api";
import dropdown from "@/components/elements/rg-dropdown";
import superagent from "superagent";
import { altBackendUrl } from "@/constants.js";
import Vue from "vue";
// import { api } from "@/helper.js";

export default {
  name: "Accounts",
  components: {
    dropdown,
    "rg-dropdown": dropdown,
    rgTabs: rg_tabs,
    rgTable: () => import("@/components/elements/Table.vue"),
  },

  // eslint-disable-next-line no-unused-vars
  setup(props, context) {
    onBeforeMount(() => {
      getSalesUsers();
    });
    let data = ref([]);
    let salesUsers = ref([]);
    // let search = ref({
    //   value: '',
    //   loading: true,
    //   timeout: null
    // })
    let resultFilter = ref({
      step: 25,
      first: 25,
      offset: 0,
      search: "",
      loading: true,
      timeout: null,
    });
    let companiesCount = ref({});
    let pages = ref({});
    let page = ref({
      current: 1,
    });

    getData();

    const dispatchSearch = (ms = 300) => {
      clearTimeout(resultFilter.value.timeout);

      resultFilter.value.timeout = setTimeout(() => {
        resultFilter.value.loading = true;
        resultFilter.value.offset = 0;
        if (localStorage.currentPage) {
          stride(page.value.current, localStorage.currentPage);
          localStorage.removeItem("currentPage");
        } else {
          page.value.current = 1;
        }
        getData(resultFilter.value.search);
      }, resultFilter.value.search?.length && ms);
    };

    dispatchSearch(0);

    function updateSalesRepresentative(row, event) {
      const updateCompanyMutation = `mutation Mutation($companyId: Int, $companySalesRepresentativeId: Int) {
        Company(id: $companyId, sales_representative_id: $companySalesRepresentativeId) {
          id
        }
      }`;
      superagent
        .post(altBackendUrl)
        .send({
          query: updateCompanyMutation,
          variables: {
            companyId: row.id,
            companySalesRepresentativeId: parseInt(event.target.value),
          },
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then(() => {
          localStorage.currentPage = page.value.current;
          this.$router.go();
        });
    }

    function getData(search = resultFilter.value.search) {
      search =
        search?.length >= 3 ? JSON.stringify(search) : JSON.stringify("");
      const gqQueryCompanies = `query Query{
        Companies(limit:${resultFilter.value.first}, skip: ${resultFilter.value.offset}, search: ${search}) {
          id,
          title,
          granitId,
          Manager{
            id
          },
          SalesConsultant {
            id
          }
          sap_id
      }
      Statistics{
        totalCompaniesCount(search: ${search})
      }
    }`;
      superagent
        .post(altBackendUrl)
        .send({
          query: gqQueryCompanies,
        })
        .set(
          "Authorization",
          `Bearer ${context.root.$store.state.user.apiToken}`
        )
        .then((res) => {
          let Companies = [];
          res.body.data.Companies.forEach((item) => {
            Companies.push({
              id: item?.id || null,
              title: item?.title || null,
              granit_id: item?.granitId || null,
              sales_manager_id: item?.SalesConsultant?.id || null,
              managing_user_id: item?.Manager?.id || null,
              sap_id: item?.sap_id || null,
            });
          });
          data.value = formatInput(Companies);
          companiesCount.value = res.body.data.Statistics.totalCompaniesCount;
          pages.value = companiesCount.value / resultFilter.value.step;
        })
        .finally(() => {
          resultFilter.value.loading = false;
        });
    }

    function formatInput(inp) {
      let tmpArr = [];
      for (const obj of inp) {
        tmpArr.push(flattenInput(obj));
      }
      return tmpArr;
    }

    function flattenInput(obj) {
      let tempObj = {};
      for (const [key, value] of Object.entries(obj)) {
        let tempObj2 = {};
        if (typeof obj[key] === "object" && obj[key] != null) {
          tempObj2 = flattenInput(obj[key]);
          Object.assign(tempObj, tempObj2);
        } else {
          tempObj[key] = value;
        }
      }
      return tempObj;
    }

    function clipboardSuccessHandler() {
      Vue.$toast.open({
        message: "Erfolgreich kopiert",
        icon: "clipboard-check",
      });
    }

    function stride(from, to) {
      // console.log(
      //   `striding with stepsize ${resultFilter.value.step} : ${
      //     from > to ? to + " <= " + from : from + " => " + to
      //   }`
      // );
      page.value.current = to;
      resultFilter.value.offset =
        page.value.current === 1
          ? 0
          : resultFilter.value.step * page.value.current -
            resultFilter.value.step;
      // console.log(
      //   `Showing results: ${
      //     resultFilter.value.step * page.value.current - resultFilter.value.step
      //   } to ${resultFilter.value.step * page.value.current}`
      // );
      //Fetch new data
      getData();
    }

    function createTicket(ctx, subject = "") {
      superagent
        .get(
          `https://repguide-rest.fricke-holding.workers.dev/api/legacy/user/by-id/${ctx.managing_user_id}`
        )
        .then((res) => {
          ctx.login = res.body.data.User.login;
        })
        .finally(() => {
          window.open(
            `https://frixlab.fricke.de/innovationlab/repguide/support/-/issues/new?issue[title]=${ctx.managing_user_id}%20-%20${ctx.login}%20-%20${ctx.granitid}%20-%20${subject}`,
            "_blank"
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function getSalesUsers() {
      superagent
        .get(
          "https://repguide-rest.fricke-holding.workers.dev/api/legacy/users/by-roles/sales"
        )
        .then((res) => {
          salesUsers.value = res.body.data.Users.sort((a, b) =>
            a.prename > b.prename ? 1 : -1
          );
        });
    }

    return {
      data,
      dispatchSearch,
      clipboardSuccessHandler,
      getData,
      pages,
      page,
      resultFilter,
      companiesCount,
      stride,
      createTicket,
      salesUsers,
      updateSalesRepresentative,
    };
  },
};
</script>
